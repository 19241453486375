import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import { parse, isWithinInterval } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import {Row } from "react-bootstrap";
import Swal from "sweetalert2";
export default function Investmenthistories() {
  const [data, setData] = useState([]);
  const [RemainsBalance, setRemainsBalance] = useState("0");
  const [searchTerm, setSearchTerm] = useState(""); 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [fromDate, setFromDate] = useState(null); // Date object
  const [toDate, setToDate] = useState(null);
  const [allData, setAllData] = useState([]);
  const navigate = useNavigate();
  const customer_id = localStorage.getItem("customer_id");
  useEffect(() => {
    getProfile();
  }, []);
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };
  const getProfile = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}swan-wealth-use-self-balance-for-investment-history-logs`,
        {}, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRemainsBalance(response.data.RemainsBalance);
      setData(response.data.data);
      setAllData(response.data.data);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  const filteredData = data
  ? data.filter((item) => {
      const search = searchTerm.toLowerCase();
      return (
        item.Company_name.toLowerCase().includes(search)
      
      );
    })
  : [];


  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const parseDateString = (dateString) => {
    return parse(dateString, "dd-MM-yyyy", new Date());
  };
  const handleSearch = () => {
    if (!fromDate || !toDate) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please choose a valid date!",
        // timer: 2000
      });
    }
    if (fromDate && toDate) {
      const filteredTransactions = allData.filter((item) => {
        const transactionDate = parseDateString(item.plan_duration_from);
        return transactionDate >= fromDate && transactionDate <= toDate;
      });
      setData(filteredTransactions);
    } else {
      setData(allData);
    }
  };

  const handleRefresh = () => {
    setFromDate("");
    setToDate("");
    setSearchTerm("");
    getProfile();
  };
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="mb-0">Earn Amount History</h3>
            </div>
            <div className="col-lg-4 text-end">
            <h3 className={`mb-0 ${RemainsBalance > 0 ? 'greenWallet' : RemainsBalance < 0 ? 'redWallet' : 'greenWallet'}`}>
  <MdOutlineAccountBalanceWallet className="fs-4" /> {RemainsBalance}
</h3>

            </div>
            <div className="col-lg-2 text-end">
              <Link to="/Company-Earn" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-3">
            <form>
  <div className="input-group input-search-width">
    <input
      className="form-control form-control-sm shadow-none search"
      type="search"
      placeholder="Search by name, company, or plan"
      aria-label="search"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  </div>
</form>
            </div>
            <div className="col-lg-9 text-end">
            <Row>
                <div className="col-md-4 form_details">
                 
                 
                        <div className="form-group local-forms">
                  <label className="form-label">From Date</label>
               
                  <DatePicker className="form-control"
                      selected={fromDate}
                      onChange={handleFromDateChange}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD-MM-YYYY"
                      customInput={
                        <InputMask mask="99/99/9999" maskChar={null} />
                      }
                    />
                </div>
                </div>
                <div className="col-md-4 form_details">
                 
                 
                 <div className="form-group local-forms">
           <label className="form-label">To Date</label>
        
           <DatePicker className="form-control"
                      selected={toDate}
                      onChange={handleToDateChange}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD-MM-YYYY"
                      customInput={
                        <InputMask mask="99/99/9999" maskChar={null} />
                      }
                    />
         </div>
         </div>
                
                <div className="col-md-4 d-flex gap-1 justify-content-evenly  align-item-space-between">
                  {/* <div className="mb-3"> */}
                  <button className="btn btn-primary " onClick={handleSearch}>
                    Search
                  </button>
                  <button className="btn btn-dark " onClick={handleRefresh}>
                  Refresh
                  </button>
                  {/* <button className="btn btn-dark " onClick={handleDownload}>
                  <FaCloudDownloadAlt className="fs-4 text-light" />
                  </button> */}
                  {/* <MdArrowOutward className="" /> */}
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Company Name</th>
                  <th>Payment Type</th>
                  <th>Transaction Amount</th>
                  <th>Transaction Date</th>
                  {/* <th>Transaction Date Timings</th> */}
                  <th>Type</th>
                  
                </tr>
              </thead>
              <tbody>
              {paginatedData.length > 0 ? (
    paginatedData.map((item, index) => (
        <tr key={item.id || index}>
            <td>{startIndex + index + 1}</td>
                      <td>{item.Company_name}</td>
                      <td style={{ color: item.payment_type == 'Debit' ? 'red' : item.payment_type === 'Credit' ? 'green' : 'black' }}>
                      {item.payment_type}
                    </td>
                    <td
  style={{
    color:
      item.transaction_amount > 0
        ? 'green'
        : item.transaction_amount < 0
        ? 'red'
        : 'black',
  }}
>
  {item.transaction_amount}
</td>

                      {/* <td>{item.transaction_date}</td> */}
                      <td>{item.transaction_dateTimings}</td>
                      <td>{item.type.replace(/_/g, " ")
                                        .replace(/\b\w/g, (char) =>
                                          char.toUpperCase()
                                        )}</td>
                    
                    </tr>
                  ))
                ) : (
                    <tr>
                        <td colSpan="12" className="text-center py-4">
                            No data found.
                        </td>
                    </tr>
                )}
              </tbody>
            </table>
          </div>
          {totalPages > 1 && (
                <div className="pagination mt-3 mb-3 mx-3">
                  <button
                    className="btn btn-sm btn-light"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <IoIosArrowBack />
                  </button>
                  {[...Array(totalPages).keys()].map((pageNumber) => (
                    <button
                      key={pageNumber + 1}
                      className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                        ? "btn-light text-primary"
                        : "btn-light"
                        }`}
                      onClick={() => handlePageChange(pageNumber + 1)}
                    >
                      {pageNumber + 1}
                    </button>
                  ))}
                  <button
                    className="btn btn-sm btn-light"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <IoIosArrowForward />
                  </button>
                </div>
              )}
        </div>
      </div>
    </>
  );
}
