import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import userprofile from "../../assets/img/team/avatar.png";
import { FaEdit } from "react-icons/fa";

const Profile = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [profileinfo, setProfileinfo] = useState();
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fileimage, setFileimage] = useState();
  const [fullname, setFullname] = useState(null);
  // alert(fullname)
  const [BaseUrl, setBaseUrl] = useState(null);
  const broker_id = localStorage.getItem("broker_id");
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    address: "",
    phone: "",
    altername_phone: "",
    profile: null,
  });
  const [errors, setErrors] = useState({});
  const [newImage, setNewImage] = useState(null);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", broker_id);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}show-swan-wealth-Profile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data.data;
      const baseurl = response.data.image_url;
      setBaseUrl(baseurl);
      
      setFormData(data[0]);
    
     setFullname(data[0].first_name)
    
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "name") {
      formattedValue = value
        .replace(/[^A-Za-z]/g, "")  
        .slice(0, 40);              
    }
     
    if (name === "phone" && (value.length > 10 || isNaN(value))) {
      return;
    }
    if (name === "altername_phone") {
      if (value.length > 10 || isNaN(value)) {
        // errors.altername_phone = "Alternative Phone must be a 10-digit number.";
        return;
      } 
    }
    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileimage(URL.createObjectURL(e.target.files[0]));
    setNewImage(file);
    setFormData({
      ...formData,
      profile: "",
    });
  };

  // const validateName = (value) => {
  //   if (!value || !value.trim()) {
  //     return "This field is required";
  //   }
  //   return "";
  // };

  const validateEmail = (value) => {
    if (!value) {
      return "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      return "Email address is invalid";
    }
    return "";
  };

  const validatePhone = (value) => {
    if (!value || !value.trim()) {
      return "Phone number is required";
    } else if (!/^\d{10}$/.test(value)) {
      return "Phone number must be exactly 10 digits";
    }
    return "";
  };
  const validateForm = () => {
    const { first_name, email } = formData;
    const newErrors = {};
    let isValid = true;

    // First name validation
    if (!first_name.trim()) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Name cannot be empty',
            // timer: 2000s
        });
        return false;
    }

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Email cannot be empty',
            // timer: 2000
        });
        return false;
    } else if (!emailPattern.test(email)) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Please enter a valid email address',
            // timer: 2000
        });
        return false;
    }

    // Additional field validation
    for (const field in formData) {
        switch (field) {
            case "first_name":
            case "middle_name":
            case "last_name":
            case "address":
                // newErrors[field] = validateName(formData[field]); // Assuming validateName is a function that validates name fields
                break;
            case "email":
                newErrors[field] = validateEmail(formData[field]); 
                break;
            // case "phone":
            //   newErrors[field] = validatePhone(formData[field]); // Assuming validatePhone is a function that validates phone
            //   break;
            case "alternate_phone":
                break;
            default:
                break;
        }
        if (newErrors[field]) {
            isValid = false;
        }
    }

    setErrors(newErrors);
    return isValid;
};


const handleSubmit = async (e) => {
  e.preventDefault();

  // Validate the form before proceeding
  if (!validateForm()) return;
  const confirmProfileUpdate = await Swal.fire({
    title: 'Update Profile',
    text: "Are you sure you want to update your profile details? Ensure all the information is correct.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6', // Blue for confirmation
    cancelButtonColor: '#d33', // Red for cancel
    confirmButtonText: 'Yes, Update!',
    cancelButtonText: 'Cancel',
  });
  
  if (!confirmProfileUpdate.isConfirmed) {
    Swal.fire({
      icon: 'info',
      title: 'Cancelled',
      text: 'Profile update was cancelled.',
    });
    return;
  }
  
  try {
    const token = localStorage.getItem("bear_token");
console.warn(formData.altername_phone)
    // Prepare the form data to be updated
    let formDataToUpdate = new FormData();
    formDataToUpdate.append("first_name", formData.first_name);
    formDataToUpdate.append("middle_name", formData.middle_name);
    formDataToUpdate.append("last_name", formData.last_name);
    formDataToUpdate.append("email", formData.email);
    formDataToUpdate.append("address", formData.address);
    formDataToUpdate.append("user_id", broker_id);
    formDataToUpdate.append("phone", formData.phone);
    formDataToUpdate.append("alt_no", formData.altername_phone);
    formDataToUpdate.append("status", "1");

    // Handle the profile image upload
    if (newImage) {
      formDataToUpdate.append("profile", newImage);
    } else {
      const storedImage = localStorage.getItem("profile_image");
      if (storedImage) {
        const blob = await (await fetch(storedImage)).blob();
        formDataToUpdate.append("profile", blob);
      }
    }

    // Send the form data to the API
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}update-swan-wealth-Profile`,
      formDataToUpdate,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const data = response.data.status;

    // Handle the response from the API
    if (data !== 0) {
      const MySwal = withReactContent(Swal);
      if (data === 1) {
        MySwal.fire({
          title: "Success",
          text: "Profile Updated Successfully!!",
          icon: "success",
          color:"white",
          background: 'transparent',
          showConfirmButton: true,
        }).then(() => {
          getProfile();
          // window.location.reload();
        });
      } 
    }else {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: "Error",
        text: response.data.message || "An error occurred while updating the profile.",
        icon: "error",
        showConfirmButton: true,
      });
    }


    getProfile();
  } catch (error) {
    console.error("Error updating user profile:", error);
  }
};


  return (
    <div className="page">
      <form onSubmit={handleSubmit} className="">
        <div className="d-flex justify-content-start align-items-start">
          <div className="card width_30">
            <div className="card-header card-height">
              <div className="avatarprofile avatar_image_width d-flex justify-content-center mx-auto">
                {fileimage == null ? (
                  <img
                    className="rounded-circle img-thumbnail shadow-sm mx-auto"
                    id="profileimage"
                    src={
                      formData.profile
                        ? `${BaseUrl}/${formData.profile}`
                        : userprofile
                    }
                    width={150}
                    height={150}
                    alt=""
                  />
                ) : (
                  <img
                    className="rounded-circle img-thumbnail shadow-sm mx-auto"
                    id="profileimage"
                    src={fileimage}
                    width={150}
                    height={150}
                    alt=""
                  />
                )}
                <label htmlFor="profileimage" className="custom-file-upload">
                  <FaEdit className="edit-icon" />
                  <input
                    id="profileimage"
                                    accept=".jpeg,.jpg,.png"
  type="file"
                    name="profile"
                    onChange={handleImageChange}
                    style={{
                      position: "absolute",
                      opacity: 0,
                      top: 0,
                      left: 0,
                      cursor: "pointer",
                    }}
                  />
                </label>
              </div>

              <h4 className="mb-1 editable-cell">
                <div className="editable-field mt-2">
                  <h5 className="editable-input w-100 text-center capitalize">
                    {fullname}
                  </h5>
                </div>
              </h4>
            </div>
          </div>

          <div
            id="Profile"
            className="tabcontent"
            style={{ display: activeTab === 1 ? "block" : "none" }}
          >
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">Profile</h3>
                </div>
              </div>
              <div className="card-body">
                <div className="row g-3">
                  {/* <div className="col-lg-6">
                    <label className="form-label" htmlFor="email">
                      fullname Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                    />
                    {errors.first_name && (
                      <div className="text-danger">{errors.first_name}</div>
                    )}
                  </div> */}
                  <div className="row g-3 mt-2">
                    <div className="col-md-6 form_details mt-3">
                      <div className="form-group local-forms">
                        <label className="form-label" htmlFor="fullname">
                          Full Name <span className="login-danger">*</span>
                        </label>
                        <input
                          className={`form-control ${errors.first_name ? "input-error" : ""}`}
                          type="text"
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleChange}
                        />
                        {/* {errors.first_name && (
                          <div className="text-danger">{errors.first_name}</div>
                        )} */}
                      </div>
                    </div>



                    <div className="col-lg-6 form_details mt-3">
                      <div className="form-group local-forms">
                        <label className="form-label" htmlFor="email">
                          Email<span className="login-danger">*</span>
                        </label>
                        <input
                          className={`form-control ${errors.email ? "input-error" : ""}`}
                          id="email"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {/* {errors.email && (
                          <div className="text-danger">{errors.email}</div>
                        )} */}
                      </div>
                    </div>

                    <div className="col-lg-6 form_details mt-3">
                      <div className="form-group local-forms">
                        <label className="form-label" htmlFor="phone">
                          Phone
                        </label>
                        <input
                          className="form-control"
                          // className={`form-control ${errors.phone ? "input-error" : ""}`}
                          id="phone"
                          type="text"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                        {/* {errors.phone && (
                          <div className="text-danger">{errors.phone}</div>
                        )} */}
                      </div>
                    </div>

                    <div className="col-lg-6 form_details mt-3">
                      <div className="form-group local-forms">                  
                        <label className="form-label" htmlFor="altername_phone">
                        Alternative Phone
                      </label>
                        <input
                          className="form-control"
                          id="altername_phone"
                          type="text"
                          name="altername_phone"
                          value={formData.altername_phone}
                          onChange={handleChange}
                        />
                       
                      </div>
                    </div>

                    <div className="col-lg-6 form_details mt-3">
                      <div className="form-group local-forms">
                        <label className="form-label" htmlFor="address">
                          Address
                        </label>
                        <textarea
                          className="form-control"
                          id="address"
                          type="text"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                        />
                       
                      </div>
                    </div>

                    <div className="col-lg-12 text-end">
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Add other tab contents here as needed */}
      </form >
    </div>
  );
};

export default Profile;
