import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { MdPersonAddAlt1 } from "react-icons/md";
import company from "../../assets/img/icons/building.png";
import downloadProfile from "../../assets/img/gallery/download.png";
import { PiListNumbersFill } from "react-icons/pi";
import {
  FaUserAlt,
  FaRegBuilding,
  FaRegEnvelope,
  FaMapMarkerAlt,
  FaPhone,
  FaInfoCircle,
  FaImage,
} from "react-icons/fa";
import { FaDollarSign, FaChartLine } from 'react-icons/fa';

import { FaRupeeSign } from "react-icons/fa";
import { IoMdPersonAdd } from "react-icons/io";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { IoPersonSharp } from "react-icons/io5";
import { FaBuilding, FaIdCard } from "react-icons/fa";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { FaHashtag } from "react-icons/fa";
import optional from "../../assets/img/optional.jpg";
import { RiLockPasswordFill } from "react-icons/ri";
import { Modal, Button } from "react-bootstrap";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { GiCalendarHalfYear } from "react-icons/gi";
import { FcMoneyTransfer } from "react-icons/fc";

import { TbCalendarMonth } from "react-icons/tb";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";

import {
  FaMoneyCheckAlt,
  FaRegCalendarAlt,
  FaTimesCircle,
  FaPhoneAlt,
} from "react-icons/fa";

export default function ViewBusiness() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [business, setBusiness] = useState(null);
  const [businesss, setBusinesss] = useState(null);
  const [baseurl23, setbaseurl23] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [baseurl, setBaseurl] = useState("");
  const [AllEmployee, setAllEmployee] = useState(0);
  const [AllEmployee1, setAllEmployee1] = useState(0);
  const [AllEmployee2, setAllEmployee2] = useState(0);
  const [Allcompanyfund, setAllcompanyfund] = useState(0);
  const [AllBusinesesCustomers, setBusinesesCustomers] = useState(0);
  const company_id = localStorage.getItem("company_id");
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  useEffect(() => {
    const fetchBusiness = async () => {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("cust_id", company_id);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}view-swan-wealth-company`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setBusiness(response.data.data);

        setAllEmployee(response.data.tatalCustomerTransactions);  
        setAllcompanyfund(response.data.overAllComapnyFund);
        setAllEmployee1(response.data.totaInvestedPlans);
        setAllEmployee2(response.data.totalCustomerFund);

        const data11 = response.data.customerInvestmentDetails;
        console.warn("Ddd", data11);
        setBusinesss(data11);

        setBaseurl(response.data.url);
        const data123 = response.data.plan_image_url;
        console.warn(response.data);
        setbaseurl23(data123);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBusiness();
  }, []);

  // const fetchDatabussinesscount = async () => {
  //   try {
  //     setLoading(true);
  //     const token = localStorage.getItem("bear_token");
  //     const broker_id = localStorage.getItem("broker_id");

  //     const formData = new FormData();
  //     formData.append("user_id", broker_id);

  //     const config = {
  //       method: "post",
  //       url: `${process.env.REACT_APP_API_URL}small-admins-business-customer-lists`,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "multipart/form-data",
  //       },
  //       data: formData,
  //     };

  //     const response = await axios(config);
  //     const data = response.data.data;
  //     const data1 = response.data.image_url;

  //     const filteredData = data.filter((item) => item.company_id == company_id);

  //     const totalFilteredEmp = filteredData.length;
  //     setBusinesesCustomers(totalFilteredEmp);

  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setLoading(false);
  //   }
  // };

  const fetchDatacustomercount = async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-wealth-company-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      const filteredData = data.filter((item) => item.company_id == company_id);

      const totalFilteredEmp = filteredData.length;
      // setAllEmployee(totalFilteredEmp);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDatacustomercount();
    // fetchDatabussinesscount();
  }, []);

  const handleClickNCostumerCompanyId = (company_id) => {
    localStorage.setItem("company_id_NCustumer", company_id);
    navigate("/Company-Normal-Customer");
  };
  const handleClickBCostumerCompanyId = (company_id) => {
    localStorage.setItem("company_id_BCustumer", company_id);
    navigate("/Company-Bussiness-Customer");
  };
  const handleClickadd = (invest_id, invest_plain_id) => {
    navigate("/InvestmenteUpdate");
    localStorage.setItem("InvestUpdate_id", invest_id);
    localStorage.setItem("InvestUpdateplan_id", invest_plain_id);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Company Analytics</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Companymangement" className="mx-1">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-3 mb-3">
        <div className="col-md-4 col-xxl-3">
          <div className={`linkWithoutUnderline `}>
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 d-flex align-items-center">
                  Total Transactions
                </h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <PiListNumbersFill className="fs-4 text-success" />
                  </div>
                  <div className="col ps-0">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {AllEmployee}
                    </p>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-xxl-3">
          <div className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2"> Total Invested Plans</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div  className={`col pe-0 ${AllEmployee1 > 0 ? 'infoWallet' : AllEmployee1 < 0 ? 'redWallet' : 'infoWallet'}`}>
                    <FaChartLine className="fs-4 " />
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                      <p  className={`font-sans-serif lh-1 mb-1 fs-5  ${AllEmployee1 > 0 ? 'infoWallet' : AllEmployee1 < 0 ? 'redWallet' : 'infoWallet'}`}>
                        {AllEmployee1}
                      </p>
                    </div>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-xxl-3">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Total Customer Fund</h5>
            </div>
            <div
              className={`card-body d-flex flex-column justify-content-end ${
                AllEmployee2 > 0
                  ? "success"
                  : AllEmployee2 < 0
                  ? "danger"
                  : "dark"
              }`}
            >
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <FaRupeeSign
                    className={`fs-4 ${
                      AllEmployee2 > 0
                        ? "text-success"
                        : AllEmployee2 < 0
                        ? "text-danger"
                        : "text-dark"
                    }`}
                  />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                    <p
                      className={`font-sans-serif lh-1 mb-1 fs-5 ${
                        AllEmployee2 > 0
                          ? "text-success"
                          : AllEmployee2 < 0
                          ? "text-danger"
                          : "text-dark"
                      }`}
                    >
                      {AllEmployee2 || 0}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          {business &&
            business.map((business) => (
              <div className="row">
                <div className="col-lg-5 d-flex align-items-center">
                  <h5 className="mb-0 capitalize d-flex align-items-center">
                    {business.profile_image === null ? (
                      <>
                        <IoPersonSharp className="fs-6 text-dark me-1" />
                      </>
                    ) : (
                      <img
                        src={`${baseurl}/${business.profile_image}`}
                        heig
                        ht={35}
                        width={35}
                        alt="plan_image"
                        className="img-fluid rounded-circle fs-6  me-1"
                      />
                    )}{" "}
                    {business.first_name}
                  </h5>
                </div>
                <div className="col-lg-4 text-end ">
                  <h3 className="mb-0 text-success align-items-end">
                    {" "}
                    <MdOutlineAccountBalanceWallet className="fs-4 text-success" />{" "}
                    {(business.user_credit).toFixed(0)}
                  </h3>
                </div>
                <div className="col-lg-3 text-end">
                  <h6 className="mb-0 text-info">
                    {" "}
                    <MdOutlineMarkEmailRead className="fs-4 text-info" />{" "}
                    {business.email}
                  </h6>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="card-body">
        <div className="card mb-3">
          <div className="card-header">
            <div className="card-title mb-0">Customer Details</div>
          </div>
          <div className="card-body" style={{ backgroundColor: "#f9f9f9" }}>
            <div className="card-body bg-body-tertiary">
              <div id="plan_new">
                <div className="row d-flex justify-content-center">
                  {business &&
                    business.map((business) => (
                      <div className="col-md-12 mb-2">
                        <div class="plan" key={business.id}>
                          <div class="first-cut">
                            <div>
                              <div class="price">
                                <div class="popular">
                                  <div class="inner active">
                                    {/* <p>Active</p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex imageprofile">
                              {business.profile_image === null ? (
                                <img
                                  src={downloadProfile}
                                  alt="profile Image"
                                  className="img-fluid"
                                  width={150}
                                  height={150}
                                />
                              ) : (
                                <img
                                  src={`${baseurl}/${business.profile_image}`}
                                  className="img-fluid"
                                  alt="plan_image"
                                  width={150}
                                  height={150}
                                />
                              )}{" "}
                            </div>
                            <div className="text-center ">
                              <h3 className="capitalize">
                                <strong>Full Name :</strong>{" "}
                                {business.first_name}
                              </h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <ul className="subscription">
                                <li>
                                  <h2 className="capitalize"> Personal Info</h2>
                                </li>

                                <li>
                                  <FaRegEnvelope />
                                  <strong>Email</strong>{" "}
                                  <span className="mx-2">:</span>{" "}
                                  {business.email}
                                </li>
                                <li>
                                  <FaPhoneAlt />
                                  <strong>Phone No.</strong>{" "}
                                  <span className="mx-2">:</span>{" "}
                                  {business.phone}
                                </li>

                                <li>
                                  <FaPhoneAlt />
                                  <strong>Alt Phone No. </strong>{" "}
                                  <span className="mx-2">:</span>{" "}
                                  {business.altername_phone}
                                </li>

                                <li>
                                  {" "}
                                  <FaMapMarkerAlt />
                                  <strong>Address</strong>{" "}
                                  <span className="mx-2">:</span>{" "}
                                  {business.address} 
                                </li>

                                <li className="d-flex">
                                  {" "}
                                  <FaUserAlt />
                                  <strong>Gender</strong>{" "}
                                  <span className="mx-2">:</span>{" "}
                                  <div className="d-flex align-items-center">
                                    {business.gender}
                                  </div>
                                </li>
                                <li>
                                  <FaUserAlt />
                                  <strong>Marital Status</strong>{" "}
                                  <span className="mx-2">:</span>{" "}
                                  {business.marital_status}
                                </li>
                                <li className="d-flex">
                                  {" "}
                                  <FaIdCard />
                                  <strong>PAN Number</strong>{" "}
                                  <span className="mx-2">:</span>{" "}
                                  <div className="d-flex align-items-center">
                                    {business.pannumber}
                                  </div>
                                </li>

                                <li className="d-flex">
                                  {" "}
                                  <FaIdCard />
                                  <strong>Aadhaar Number</strong>{" "}
                                  <span className="mx-2">:</span>{" "}
                                  <div className="d-flex align-items-center">
                                    {business.aadharnumber}
                                  </div>
                                </li>
                                <li className="d-flex">
                                  {" "}
                                  <FaRegBuilding  />
                                  <strong>Company Name</strong>{" "}
                                  <span className="mx-2">:</span>{" "}
                                  <div className="d-flex align-items-center capitalize">
                                    {business.company_name}
                                  </div>
                                </li>
                              </ul>
                              <ul className="subscription">
                                <li className="d-flex">
                                  {" "}
                                  <h2 className="capitalize"> Bank Details</h2>
                                </li>
                                <li className="d-flex">
                                  {" "}
                                  <FaIdCard />
                                  <strong>Customer Account Number</strong>{" "}
                                  <span className="mx-2">:</span>{" "}
                                  <div className="d-flex align-items-center">
                                    {business.cust_ac_no}
                                  </div>
                                </li>
                                <li className="d-flex">
                                  {" "}
                                  <FaIdCard />
                                  <strong>Customer Holder Name</strong>{" "}
                                  <span className="mx-2">:</span>{" "}
                                  <div className="d-flex align-items-center">
                                    {business.acc_holder_name}
                                  </div>
                                </li>
                                <li className="d-flex">
                                  {" "}
                                  <FaBuilding />
                                  <strong>Branch Name</strong>{" "}
                                  <span className="mx-2">:</span>{" "}
                                  <div className="d-flex align-items-center">
                                    {business.bank_branch}
                                  </div>
                                </li>
                                <li className="d-flex">
                                  <FaMapMarkerAlt />
                                  <strong>Bank Address</strong>{" "}
                                  <span className="mx-2">:</span>{" "}
                                  <div className="d-flex align-items-center">
                                    {business.bank_address}
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <Modal
                              show={show}
                              onHide={handleClose}
                              size="xl"
                              fullscreen="xxl-down"
                              style={{ height: "auto" }}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Images</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="row">
                                  <div className="col-md-4">
                                    <strong>PAN Image</strong>
                                    <img
                                      src={`${baseurl}/${business.pan_image}`}
                                      alt="PAN Image"
                                      className="img-fluid my-2 hoverable"
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <strong>Aadhar Front Image</strong>
                                    <img
                                      src={`${baseurl}/${business.uan_image}`}
                                      alt="Aadhar Front Image"
                                      className="img-fluid my-2 hoverable"
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <strong>Aadhar Back Image</strong>
                                    <img
                                      src={`${baseurl}/${business.back_uan_image}`}
                                      alt="Aadhar Back Image"
                                      className="img-fluid my-2 hoverable"
                                    />
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            <div>
                              <button class="button" onClick={handleShow}>
                                View All Images
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <hr />
            </div>
            <table className="table">
              <tbody></tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <React.Fragment>
              <div className="card mb-3">
                {businesss && businesss.length > 0 && (
                  <div className="card-header border-bottom d-flex justify-content-between">
                    <div className="card-title mb-0">
                      Customer Invested Plans
                    </div>
                    {/* <button   onClick={() => handleClickaddHistory()} className="text-end btn bg-success text-light">
           History Logs
           </button> */}
                  </div>
                )}
                <div className="card-body bg-body-tertiary">
                  <div id="plan_new">
                    <div className="row">
                      {businesss &&
                        businesss.map((business) => (
                          <div className="col-md-4 mb-2">
                            <div class="plan" key={business.id}>
                              <div class="first-cut">
                                <div>
                                  <h2 className="capitalize">
                                    {" "}
                                    {business.PlanName}
                                  </h2>
                                  <div class="price">
                                    <div class="price-details">
                                      <p class="cent">
                                        <GiCalendarHalfYear />
                                        <strong>Plan Duration</strong>{" "}
                                        <span className="mx-2">:</span>{" "}
                                        {`${business.plan_duration} ${business.plan_duration_type}`}
                                      </p>
                                    </div>
                                    {business.plan_status === "pending" ? (
                                      <div class="popular">
                                        <div class="inner">
                                          <p>Pending</p>
                                        </div>
                                      </div>
                                    ) : (
                                      <div class="popular">
                                        <div class="inner active">
                                          <p>Active</p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="d-flex imageprofile">
                                  <img
                                    src={
                                      business.plan_investment_image != "NA"
                                        ? `${baseurl23}/${business.plan_investment_image}`
                                        : optional
                                    }
                                    alt="Plan Image"
                                    height={200}
                                    className="img-fluid hoverable"
                                  />
                                </div>

                                <ul className="subscription">
                                  <li>
                                    <FaRegCalendarAlt />
                                    <strong>Plan Start Date</strong>{" "}
                                    <span className="mx-2">:</span>
                                    {business.plan_start_date}
                                  </li>
                                  <li>
                                    <FcMoneyTransfer />
                                    <strong>Interest Earned</strong>{" "}
                                    <span className="mx-2">:</span>{" "}
                                    {(
                                      business.expected_profit -
                                      business.invested_amount
                                    ).toFixed(2)}
                                  </li>
                                  <li>
                                    <RiMoneyRupeeCircleFill />
                                    <strong>Invested Amount</strong>{" "}
                                    <span className="mx-2">:</span>{" "}
                                    {business.invested_amount}
                                  </li>
                                  <li>
                                    <RiMoneyRupeeCircleFill />
                                    <strong> Expected Profit</strong>{" "}
                                    <span className="mx-2">:</span>{" "}
                                    {business.expected_profit}
                                  </li>
                                  <li>
                                    {" "}
                                    <FaMoneyBillTrendUp />
                                    <strong>Interest Rate</strong>{" "}
                                    <span className="mx-2">:</span>{" "}
                                    {business.interest_rate} %
                                  </li>

                                  <li>
                                    <TbCalendarMonth />
                                    <strong>Interest Yearly View</strong>{" "}
                                    <span className="mx-2">:</span>
                                    <select className="optionyear text-success">
                                      {business &&
                                        business.yearly_interest_details.map(
                                          (yearly, index) => (
                                            <option
                                              key={index}
                                              value={yearly.year_interest}
                                            >
                                              {index + 1} Year - ₹
                                              {yearly.year_interest}
                                            </option>
                                          )
                                        )}
                                    </select>
                                  </li>

                                  <li className="d-flex">
                                    {" "}
                                    {business.is_compound === 1 ? (
                                      <FaMoneyCheckAlt />
                                    ) : (
                                      <FaTimesCircle />
                                    )}{" "}
                                    <strong>Compound Interest</strong>{" "}
                                    <span className="mx-2">:</span>{" "}
                                    <div
                                      className="d-flex align-items-center"
                                      style={{
                                        color:
                                          business.is_compound === 1
                                            ? "green"
                                            : "red",
                                      }}
                                    >
                                      {business.is_compound === 1
                                        ? "Applicable"
                                        : "Not Applicable"}
                                    </div>
                                  </li>
                                  <li>
                                    <FaRegCalendarAlt />
                                    <strong>Expected Date</strong>{" "}
                                    <span className="mx-2">:</span>{" "}
                                    {business.expected_date}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
