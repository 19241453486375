import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import { FaCreditCard, FaMoneyBillWave, FaHandshake } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import {
  FaUserAlt,
  FaUniversity,
  FaMoneyCheckAlt,
  FaCalendarAlt,
  FaHourglassHalf,
  FaFileInvoiceDollar,
  FaMoneyBillAlt,
  FaPercent,
  FaArrowLeft,
  FaTimesCircle,
  FaRegBuilding,
} from "react-icons/fa";
import optional from "../../assets/img/optional.jpg";
import { FaMoneyBillTrendUp } from "react-icons/fa6";

export default function InvestmentView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [baseurlprofil, setbaseurlprofil] = useState();
  const [baseurlprofilurl, setprofilimageurl] = useState();
  const [profilimage, setprofilimage] = useState();
  const [profilimagename, setprofilimagename] = useState();
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");
      const transactionid = localStorage.getItem("transactionid");
      const customer_id = localStorage.getItem("customer_id");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("id", transactionid);
      formData.append("cust_id", customer_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-wealth-customer-tranx-view`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      setData(data);
      setLoading(false);

      setbaseurl(response.data.trnximageUrl);
      setbaseurlprofil(response.data.data[0].trnx_image);
      setprofilimage(response.data.image);
      setprofilimageurl(response.data.profileImage);
      setprofilimagename(response.data.CustomerName);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="mb-0">Customer Transaction Details</h2>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Customer-Transaction-Details" className="text-white">
                <button className="btn btn-success"> Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {/* Transaction Details */}

        <div className="col-lg-12 mb-3">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Transaction Details</h5>
            </div>
            <div className="card-body bg-body-tertiary">
              <div id="plan_new">
                <div className="row d-flex justify-content-center">
                  {data &&
                    data.map((business) => (
                      <div className="row">
                        <div className="col-md-12 mb-2">
                          <div class="plan" key={business.id}>
                            <div class="first-cut">
                              {/* <div className="d-flex imageprofile">
                                      <img
                                        src={
                                          business.trnx_image
                                            ? `${baseurl}/${business.trnx_image}`
                                            : optional
                                        }
                                        width={150}
                                        height={150}
                                        alt="Trnx Image"
                                        className="img-fluid"
                                      />
                                    </div> */}
                              {business.transaction_status === "success" ? (
                                <div class="popular">
                                  <div class="inner active capitalize">
                                    <p>{business.transaction_status}</p>
                                  </div>
                                </div>
                              ) : (
                                <div class="popular">
                                  <div class="inner  capitalize">
                                    <p>{business.transaction_status}</p>
                                  </div>
                                </div>
                              )}

                              <div className="d-flex justify-content-between">
                                <ul className="subscription">
                                  <li>
                                    {" "}
                                    <div className="card-header">
                                      <h5 className="card-title mb-0">
                                        Transaction Details
                                      </h5>
                                    </div>
                                  </li>
                                  <li>
                                    {" "}
                                    <strong>Customer Name </strong>{" "}
                                    <span className="mx-2">:</span>{" "}
                                    <FaUserAlt className="me-2" />{" "}
                                    {profilimagename}
                                  </li>
                                  <li>
                                    <FaMoneyCheckAlt />
                                    {data.payment_mode !== "offline" ? (
                                      <strong>Transaction ID</strong>
                                    ) : (
                                      <strong>Bill ID</strong>
                                    )}
                                    <span className="mx-2">:</span>{" "}
                                    {business.transaction_id}
                                  </li>

                                  <li>
                                    {" "}
                                    <FaMoneyBillTrendUp />
                                    <strong>Transaction Amount</strong>{" "}
                                    <span className="mx-2">:</span>{" "}
                                    {business.transaction_amount}
                                  </li>

                                  <li>
                                    {" "}
                                    <FaCalendarAlt />
                                    <strong>Transaction Date</strong>{" "}
                                    <span className="mx-2">:</span>{" "}
                                    {business.transaction_date}
                                  </li>
                                  <li className="d-flex">
                                    {" "}
                                    <FaRegBuilding />
                                    <strong>Company Name</strong>{" "}
                                    <span className="mx-2">:</span>{" "}
                                    <div className="d-flex align-items-center capitalize">
                                      {business.company_name}
                                    </div>
                                  </li>

                                  {/* <li className="d-flex">
                                          {" "}
                                          {business.transaction_status  ? (
                                            <FaMoneyCheckAlt />
                                          ) : (
                                            <FaTimesCircle />
                                          )}{" "}
                                          <strong>Transaction Status</strong>{" "}
                                          <span className="mx-2">:</span>{" "}
                                          <div
                                            className="d-flex align-items-center"
                                            style={{
                                              color:
                                                business.transaction_status 
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {business.transaction_status}
                                          </div>
                                        </li> */}

                                  <li>
                                    <FaMoneyCheckAlt />
                                    <strong>Remark</strong>{" "}
                                    <span className="mx-2">:</span>{" "}
                                    <div className="d-flex align-items-center capitalize">
                                      {business.remark}
                                    </div>
                                  </li>
                                </ul>
                                <ul className="subscription">
                                  <li className="d-flex">
                                    <div className="card-header">
                                      <h5 className="card-title mb-0">
                                        Bank Details
                                      </h5>
                                    </div>
                                  </li>

                                  <li>
                                    <FaUniversity />
                                    <strong>Bank Details</strong>{" "}
                                    <span className="mx-2 capitalize">:</span>{" "}
                                    {business.bank_details}
                                  </li>
                                  <li className="capitalize">
                                    <FaCreditCard /> {/* For Payment Type */}
                                    <strong>Payment Type</strong>{" "}
                                    <span className="mx-2 ">:</span>{" "}
                                    {business.payment_type}
                                  </li>
                                  <li className="capitalize">
                                    <FaMoneyBillWave /> {/* For Payment Mode */}
                                    <strong>Payment Mode</strong>{" "}
                                    <span className="mx-2 ">:</span>{" "}
                                    {business.payment_mode}
                                  </li>
                                  <li className="capitalize">
                                    <FaHandshake />{" "}
                                    {/* For Payment Mode Type */}
                                    <strong>Payment Mode Type</strong>{" "}
                                    <span className="mx-2 capitalize">:</span>{" "}
                                    {business.payment_mode_type}
                                  </li>

                                  <li>
                                    <FaMoneyCheckAlt />
                                    <strong>Type</strong>{" "}
                                    <span className="mx-2">:</span>{" "}
                                    <div className="d-flex align-items-center capitalize">
                                      {business.type}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <Modal
                              show={show}
                              onHide={handleClose}
                              size="md"
                              fullscreen="md-down"
                              centered
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Images</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="row text-center">
                                  {" "}
                                  {/* Centers content horizontally */}
                                  <div className="col-md-12">
                                    <strong>Transaction Image</strong>
                                    <img
                                      src={
                                        business.trnx_image
                                          ? `${baseurl}/${business.trnx_image}`
                                          : optional
                                      }
                                      alt="Transaction Image"
                                      className="img-fluid my-2 hoverable"
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "400px",
                                      }} // Limits image size
                                    />
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>

                            <div>
                              <button class="button" onClick={handleShow}>
                                View Transaction Image
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
