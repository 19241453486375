import React, { useState, useEffect } from "react";
import axios from "axios";
export default function Privacypolicy() {

  const broker_id = localStorage.getItem("broker_id");
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  console.warn(data.data);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", broker_id);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}swan-wealth-cms-privacy-policy`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data.data;
      const data1 = response.data.image_url;

      setbaseurl(data1);
      setData({ data });
      console.warn("cus_cms-about-us", data1);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  return (
    <>
    <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              'url(../../assets/img/icons/spot-illustrations/corner-4.png)',
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <h2 className="card-title">Title Goes Here</h2>
            <div>
              <img
                className=""
                src={`${baseurl}/${data && data.data && data.data.image}`}
                width={100}
                height={100}
                alt=""
              />
            </div>
            <div className="card-body">
            {data && data.data && data.data.descriptions}
            </div>
          </div>
        </div>
      </div>
   
  </>
  
  )
}
