import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { BsFillBuildingsFill } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
export default function AddNormalCustomerTransaction() {
  const navigate = useNavigate();
  const broker_id = localStorage.getItem("broker_id");
  const customer_idTransaction = localStorage.getItem("customer_idTransaction");
  const customer_id = localStorage.getItem("customer_id");
  const cid = localStorage.getItem("cid");
  const c_id = localStorage.getItem("c_id");

  const [transactionAmount, setTransactionAmount] = useState("");
  const [remark, setremark] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [fileimage, setFileimage] = useState("");
  const [BaseQrUrl, setBaseQrUrl] = useState("");
  const [BaseQrImage, setBaseQrImage] = useState("");
  const [Data, setData] = useState();
  const [newImage, setNewImage] = useState(null);
  const [paymentType, setPaymentType] = useState("");
  const [type, setType] = useState("online");
  const [paymentMode, setPaymentMode] = useState("");
  const [billId, setBillId] = useState("");
  const [utrNo, setUtrNo] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const [TotalInvestcompany, setTotalInvestcompany] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;

    if (name === "transaction_amount") {
      // Allow only numeric input, remove leading zeros, and restrict to 12 digits
      if (/^[0-9]*$/.test(value) && value.length <= 12) {
        formattedValue = value.replace(/^0+/, ""); // Remove leading zeros
      } else {
        return; // Do not update state for invalid input
      }
    }else if (name === "transaction_id") {
      formattedValue = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 20);
    
  }
    // else if (name === "transaction_id") {
    //   formattedValue = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 15);
    // } 
    // else if (name === "utr_no") {
    //   formattedValue = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 15);
    // }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));

    if (name === "transaction_amount") {
      setTransactionAmount(formattedValue);
    } else if (name === "transaction_id") {
      setTransactionId(formattedValue);
    } 
    // else if (name === "bill_id") {
    //   setBillId(formattedValue);
    // } 
    // else if (name === "utr_no") {
    //   setUtrNo(formattedValue);
    // }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewImage(null);
    setFileimage("");
    if (file) {
      console.log("File type:", file.type); // Debugging step to log file type

      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        handleClearImageTr();

        Swal.fire({
          title: "Invalid File Type",
          text: "Only JPEG, JPG, and PNG files are allowed!",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        setFileimage(URL.createObjectURL(file));
        setNewImage(file);
      }
    }
    setErrors((prevErrors) => ({ ...prevErrors, trnx_image: null }));
  };

  const handleClearImageTr = () => {
    setNewImage(null);
    setFileimage("");

    const fileInput = document.getElementById("trnx_image");
    if (fileInput) {
      fileInput.value = "";
    } else {
      console.warn("File input element not found");
    }
  };

  // Payment Mode Handler
  const handlePaymentModeChange = (isOnlineMode) => {
    setType(isOnlineMode ? "online" : "offline");
    setIsOnline(isOnlineMode);
  };

  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, payment_type: null }));
    
  };
  const handlePaymentModeChange1 = (e) => {
    setPaymentMode(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, payment_mode: null }));
  };

  const handleRemark = (e) => {
    setremark(e.target.value);
  };

  const handleValidation = () => {
    let validationErrors = {};
    let hasError = false;

    if (!transactionAmount) {
      validationErrors.transaction_amount = "Transaction amount is required";
      hasError = true;
    } else if (isNaN(transactionAmount) || transactionAmount <= 0 || transactionAmount.length > 12) {
      validationErrors.transaction_amount = "Transaction amount must be a positive number and not exceed 12 digits";
      hasError = true;
    }

    if (isOnline && !transactionId) {
      validationErrors.transaction_id = "Transaction ID is required for online mode";
      hasError = true;
    } else if (isOnline && !/^[a-zA-Z0-9]+$/.test(transactionId)) {
      validationErrors.transaction_id = "Transaction ID must be alphanumeric";
      hasError = true;
    }

    // if (!isOnline && !billId) {
    //   validationErrors.bill_id = "Bill ID is required for offline mode";
    //   hasError = true;
    // }

    if (!newImage) {
      validationErrors.trnx_image = "Transaction image is required";
      hasError = true;
    }

    if (!paymentType) {
      validationErrors.payment_type = "Payment type is required";
      hasError = true;
    }

    if (!paymentMode) {
      validationErrors.payment_mode = "Payment mode is required";
      hasError = true;
    }
    if (!type) {
      validationErrors.type = "Payment type is required";
      hasError = true;
    }

    setErrors(validationErrors);
    return !hasError;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("bear_token");
  
    // Check if it's a credit transaction (you can change this condition based on how you define credit transactions)
    if (paymentType === "credit") {
      // Show the mandatory steps message with left alignment and red color
      const result = await Swal.fire({
        icon: "warning",
        title: "Mandatory Steps",
        html: `
         <div style="text-align: left; color: red; font-weight: bold;">
            If you credit the customer's balance, you must follow the following mandatory steps:<br /><br />
            1. Purchase the customer plan as per the credited balance.<br />
            2. Activate the customer plan.<br /><br />
            <strong>Note:</strong> If you don't want to follow the above steps, please cancel this customer credit transaction.
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Cancel'
      });
  
      if (!result.isConfirmed) {
        // If the user cancels, exit from the function
        Swal.fire({
          icon: 'info',
          title: 'Cancelled',
          text: 'The customer credit transaction has been cancelled.',
        });
        return;
      }
    }
  
    if (!handleValidation()) {
      return;
    }
   // Show confirmation dialog before proceeding
   const confirmResult = await Swal.fire({
    title: 'Are you sure?',
    text: "Do you want to add this transaction?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Add Transaction!',
    cancelButtonText: 'Cancel',
  });

  if (!confirmResult.isConfirmed) {
    setIsSubmitting(false);
    Swal.fire({
      icon: 'info',
      title: 'Cancelled',
      text: 'The transaction was not added.',
    });
    return;
  }
    setIsSubmitting(true);
  
    const dataUpdate = new FormData();
    dataUpdate.append("user_id", broker_id);
    if (c_id) {
      dataUpdate.append("cust_id", c_id);
    } else if (cid) {
      dataUpdate.append("cust_id", cid);
    } else {
      dataUpdate.append("cust_id", customer_idTransaction);
    }
    dataUpdate.append("transaction_amount", transactionAmount);
  
    if (isOnline) {
      dataUpdate.append("transaction_id", transactionId);
      dataUpdate.append("type", "online");
      dataUpdate.append("payment_mode", "online");
    } else {
      dataUpdate.append("transaction_id", transactionId);
      dataUpdate.append("type", "offline");
      dataUpdate.append("payment_mode", "offline");
    }
  
    dataUpdate.append("trnx_image", newImage);
    dataUpdate.append("payment_type", paymentType);
    dataUpdate.append("payment_mode_type", paymentMode);
    dataUpdate.append("company_id", 1);
    dataUpdate.append("remark", remark);
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}add-swan-wealth-customers-transactions`,
        dataUpdate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.data.status === 1) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Customer Transaction Added Successfully",
        });
        setTimeout(() => {
          if (c_id) {
            window.location.href = "/Customermanagement";
          } else if (cid) {
            window.location.href = "/Add-More-plan";
          } else {
            window.location.href = "/Customer-Transaction-Details";
          }
          localStorage.removeItem("c_id");
          localStorage.removeItem("cid");
        }, 2000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message,
        });
  
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting the form.",
      });
  
      setIsSubmitting(false);
    }
  };
  
  
  

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", broker_id);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}show-swan-wealth-Profile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const bsLoginAdminQRImage = response.data.QRImageURL;
      setBaseQrUrl(bsLoginAdminQRImage);
      const bsimgLoginAdminQRImage = response.data.LoginAdminQRImage.small_admins_QR_img;
      setBaseQrImage(bsimgLoginAdminQRImage);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  const fetchData = async () => {
    try {
      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");
      const customer_id = localStorage.getItem("customer_idTransaction");
  
      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("cust_id", customer_id || c_id);
  
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}view-swan-wealth-customers`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
  
      const response = await axios(config);
      const data = response.data.data;
      const TotalInvestcompany = response.data.forTotalInvestEligibilityAmount;
      setTotalInvestcompany(TotalInvestcompany);
    
      // const firstNames = data.map(item => item.first_name); 
  
      setData(data); 
      console.warn("fgdfdg",data); 
  
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="mb-0">Add Customer Transaction{" "}
                {/* <span className="capitalize ">( {Data} )</span> */}
                </h3>
            </div>
            {Data && Data.map((daata) => {
  return (
    <div className="col-lg-4 text-end d-flex justify-content-between" key={daata.id}>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-investment`}>Total Investment Company: {TotalInvestcompany}</Tooltip>}
      >
        <h3
          className={`text-end ${TotalInvestcompany > 0 ? 'primaryWallet' : TotalInvestcompany < 0 ? 'redWallet' : 'primaryWallet'}`}
        >
          <BsFillBuildingsFill className="fs-4" />
          {TotalInvestcompany}
        </h3>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-credit`}>User Credit: {(daata.user_credit).toFixed(0)}</Tooltip>}
      >
        <h3
          className={`${daata.user_credit > 0 ? 'greenWallet' : daata.user_credit < 0 ? 'redWallet' : 'greenWallet'}`}
        >
         
          <MdOutlineAccountBalanceWallet className="fs-4" />
          {(daata.user_credit).toFixed(0)}
        </h3>
      </OverlayTrigger>
    </div>
  );
})}

            <div className="col-lg-2 text-end">
              <Link to="/Customer-Transaction-Details" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form className="row g-3" onSubmit={handleSubmit}>
            <div className="col-md-12">
              <div className="form-group local-forms d-flex align-items-center">
                <label className="w-25">
                  Payment Mode <span className="text-danger">*</span> :
                </label>
                <div className="d-flex w-75">
                  <button
                    type="button"
                    className={`btn ${isOnline ? "btn-primary" : "btn-outline-primary"}`}
                    onClick={() => handlePaymentModeChange(true)}
                  >
                    Online
                  </button>
                  <button
                    type="button"
                    className={`btn mx-1 ${!isOnline ? "btn-primary" : "btn-outline-primary"}`}
                    onClick={() => handlePaymentModeChange(false)}
                  >
                    Offline
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12">
            <div className="form-group local-forms d-flex align-items-center">
              <label htmlFor="transaction_amount" className="w-25">Transaction Amount <span className="text-danger">*</span> : </label>
              <input
                type="text"
                className={`form-control w-75 ${errors.transaction_amount ? "is-invalid" : ""}`}
                id="transaction_amount"
                name="transaction_amount"
                value={transactionAmount}
                onChange={handleInputChange}
              />
            
            </div>
            </div>
            {isOnline && (
              <>
              <div className="col-md-12">
                     <div className="form-group local-forms d-flex align-items-center">
                <label htmlFor="transaction_id" className="w-25">Transaction ID <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  className={`form-control w-75 ${errors.transaction_id ? "is-invalid" : ""}`}
                  id="transaction_id"
                  name="transaction_id"
                  value={transactionId}
                  onChange={handleInputChange}
                />
              
              </div>
              </div>
          
              </>
            )}
            {!isOnline && (
              <div className="col-md-12">
                 <div className="form-group local-forms d-flex align-items-center">
                <label htmlFor="transaction_id" className="w-25">Bill ID <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  className={`form-control w-75 ${errors.transaction_id ? "is-invalid" : ""}`}
                  id="transaction_id"
                  name="transaction_id"
                  value={transactionId}
                  onChange={handleInputChange}
                />
              
              </div>
              </div>
            )}
            <div className="col-md-12">
            <div className="form-group local-forms d-flex align-items-center">
              <label htmlFor="payment_type" className="w-25"> Payment Type <span className="text-danger">*</span> : </label>
              <select
  className={`form-select w-75 ${
    errors.payment_type ? "input-error" : ""
  }`}
  id="payment_type"
  name="payment_type"
  value={paymentType}
  onChange={handlePaymentTypeChange}
  style={{
    color: paymentType === "" ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
  }}
>
  <option value=""  className="text-dark" disabled>
    Select Payment Type
  </option>
  <option value="credit" className="text-dark">
    Credit
  </option>
  <option value="debit" className="text-dark">
    Debit
  </option>
</select>

            </div>
            </div>
            <div className="col-md-12">
              <div className="form-group local-forms d-flex align-items-center">
                <label className="w-25">
                 Payment Mode <span className="text-danger">*</span> :
                </label>
                <select
  className={`form-select w-75 ${
    errors.payment_mode ? "input-error" : ""
  }`}
  name="payment_mode"
  value={paymentMode}
  onChange={handlePaymentModeChange1}
  style={{
    color: paymentMode === "" ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
  }}
>
  <option
    value=""
    className="text-dark" disabled
  >
    Select Payment Mode
  </option>
  {!isOnline && (
    <>
      <option value="cash" className="text-dark">Cash</option>
    </>
  )}
  {isOnline && (
    <>
      <option value="upi" className="text-dark">UPI</option>
      <option value="neft" className="text-dark">NEFT</option>
      <option value="rtgs"className="text-dark">RTGS</option>
    </>
  )}
</select>

              </div>
            </div>
            <div className="col-md-12">
            <div className="form-group local-forms d-flex align-items-center">
              <label htmlFor="trnx_image" className="w-25">Transaction Image <span className="text-danger">*</span> : </label>
              <input
                                accept=".jpeg,.jpg,.png"
  type="file"
                className={`form-control w-75 ${errors.trnx_image ? "is-invalid" : ""}`}
                id="trnx_image"
                name="trnx_image"
                onChange={handleFileChange}
              />
         
            </div>
            </div>
            {fileimage && (
              <div className="row mt-3">
                <p className="col-3"></p>
                <div className="col-9">
                <div className="mt-3 image_box">
                    <img
                      src={fileimage}
                      alt="Selected Plan"
                      style={{ height: "150px", width: "150px" }}
                    />
                    <ImCross onClick={handleClearImageTr} />
                  </div>
                </div>
              </div>
            )}

{/* {isOnline && ( */}
              <>
              <div className="col-md-12">
                     <div className="form-group local-forms d-flex align-items-center">
                <label htmlFor="remark" className="w-25">Remark <span className="text-danger"></span> : </label>
                <input
                  type="text"
                  className={`form-control w-75`}
                  id="remark"
                  name="remark"
                  value={remark}
                  onChange={handleRemark}
                />
              
              </div>
              </div>
          
              </>
            {/* )} */}
            <div className="col-md-12 text-end">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
