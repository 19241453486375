import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import company from "../../assets/img/icons/building.png";
export default function Viewdetailsedit() {
    const broker_id = localStorage.getItem("broker_id");
    const Companydetailsedit_id = localStorage.getItem("company_id");
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [newImage, setNewImage] = useState(null);
    const [fileimage, setFileimage] = useState(null);
    const [baseurl, setBaseurl] = useState("");
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        email: "",
        address: "",
        phone: "",
        date: "",
        status: "1",
    });

    useEffect(() => {
        fetchData();
    }, []);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFileimage(URL.createObjectURL(file));
        setNewImage(file);
        if (file) {
            const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        
            if (!validTypes.includes(file.type)) {
              // Display SweetAlert2 alert for invalid file type
              Swal.fire({
                title: 'Invalid File Type',
                text: 'Only JPEG, JPG, and PNG files are allowed!',
                icon: 'error',
                confirmButtonText: 'OK',
              });
              return;
            }
        
            // Process the valid file
            console.log('File selected:', file.name);
          } else {
            console.log('No file selected');
          }

        
    };

    const fetchData = async () => {
        try {
            const token = localStorage.getItem("bear_token");
            const config = {
                method: "post",
                url: `${process.env.REACT_APP_API_URL}view-swan-wealth-company`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                },
                data: {
                    user_id: broker_id,
                    company_id: Companydetailsedit_id,
                    status: '1'
                }
            };

            const response = await axios(config);
            const data = response.data.data[0];
            setFormData(data);
            setBaseurl(response.data.url);
        } catch (error) {
            console.error("Error fetching user broker:", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "phone" && (value.length > 10 || isNaN(value))) {
            return;
          }
          
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    };

    const validateForm = () => {
        const { name, email,phone } = formData;
        
        const isEmpty = (value) => !value || !value.trim();
        if (isEmpty(name)) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Name is required',
                // timer: 2000
            });
            return false;
        }
        if (isEmpty(email)) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Email is required',
                // timer: 2000
            });
            return false;
        }
       
        if (isEmpty(phone)) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Phone number is required',
                // timer: 2000
            });
            return false;
        }
   

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Invalid email format',
            // timer: 2000
        });
        return false;
    }


 
    

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!validateForm()) {
            setLoading(false);
            return;
        }

        try {
            const token = localStorage.getItem("bear_token");
            const updatedFormData = new FormData();
            updatedFormData.append("user_id", broker_id);
            updatedFormData.append("company_id", Companydetailsedit_id);
         
            Object.entries(formData).forEach(([key, value]) => {
                if (value !== null && value !== "" && value !== "null") {
                    if (key !== "company_name" && key !== "profile") {
                        updatedFormData.append(key, value);
                    }
                }
            });
        
            if (newImage) {
                updatedFormData.append("profile", newImage);
            }
            const config = {
                method: "post",
                url: `${process.env.REACT_APP_API_URL}update-swan-wealth-company`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                },
                data: updatedFormData
            };

            const response = await axios(config);
            if (response.data.status === 1) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.message,
                    color:"white",
                    background: 'transparent',
                    // timer: 1500
                });
                navigate('/Companymangement');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.message,
                    // timer: 2000
                });
            }
        } catch (error) {
            console.error("Error updating user broker:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="card mb-3">
                <div className="bg-holder d-none d-lg-block bg-card" style={{ backgroundImage: "url(../../assets/img/icons/spot-illustrations/corner-4.png)" }} />
                <div className="card-body position-relative">
                    <div className="row">
                        <div className="col-lg-8">
                            <h3 className="mb-0">Update Company Details </h3>
                        </div>
                        <div className="col-lg-4 text-end">
                            <Link to="/Companymangement">
                                <button className="btn btn-success">Back</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
            <div className="card">
                <div className="card-body">
                    <Container fluid>
                        <Row>
                            {[
                                { field: "id", label: "Company ID" },
                                { field: "name", label: "Company Name" },
                                { field: "email", label: "Company Email" },
                                { field: "phone", label: "Company Phone" },
                                { field: "address", label: "Company Address" },
                                { field: "status", label: "Company Status" },
                                { field: "date", label: "Date" },
                                { field: "description", label: "Company Description" }
                            ].map(({ field, label }, index) => (
                                <Col md={6} key={index}>
                                    <div className="mb-3">
                                        <div className="col-md-12 form_details mt-2">
                                            <div className="form-group local-forms">
                                                <label className="form-label" htmlFor={field}>{label}</label>
                                                {field === "status" ? (
                                                    <select className="form-select" id={field} name={field} value={formData[field]} onChange={handleInputChange}>
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>
                                                    </select>
                                                ) : field === "description" ? (
                                                    <textarea
                                                        className="form-control"
                                                        id={field}
                                                        name={field}
                                                        value={formData[field] || ""}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <input
                                                        className="form-control"
                                                        id={field}
                                                        type="text"
                                                        name={field}
                                                        value={formData[field] || ""}
                                                        onChange={handleInputChange}
                                                        disabled={field === "id"}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                            <Col md={6}>
                                {fileimage == null && formData.profile != null ? (
                                    <img src={`${baseurl}/${formData.profile}`} height={100} width={100} alt="Profile" className="img-fluid" />
                                ) : fileimage != null ? (
                                    <img src={fileimage} height={100} width={100} alt="Profile" className="img-fluid" />
                                ) : (
                                    <img src={company} height={100} width={100} alt="Profile" className="img-fluid" />
                                )}
                            </Col>
                            <div className="col-md-6 form_details">
                                <div className="form-group local-forms">
                                    <label className="form-label" htmlFor="profile">Profile</label>
                                    <input
                                        className='form-control'
                                        id="profile"
                                        name="profile"
                                                        accept=".jpeg,.jpg,.png"
  type="file"
                                        onChange={handleImageChange}
                                    />
                                </div>
                            </div>
                            <Col md={12}>
                                <div className="mb-3 d-flex justify-content-end">
                                    <button disabled={loading} className="btn btn-sm btn-primary">
                                        Update
                                        {loading && <Spinner animation="border" style={{ width: '15px', height: '15px', marginLeft: '10px' }} />}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </form>
        </div>
    );
}
