import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import Home from "./pages/Home/Home";
import Termsandcondition from "./pages/Home/Termsandcondition";
import Aboutus from "./pages/Home/Aboutus";
import PartnerTransition from "./pages/Partnermanagement/PartnerList";
import PartnerUpdate from "./pages/Partnermanagement/PartnerUpdate";
import PartnerTransactionView from "./pages/Partnermanagement/PartnerTransactionView";
import PartnerTransactiondetails from "./pages/Partnermanagement/PartnerTransactiondetails";
import AddPartnerTransaction from "./pages/Partnermanagement/AddPartnerTransaction";
import PartnerDetails from "./pages/Partnermanagement/PartnerDetails";
import AddPartner from "./pages/Partnermanagement/AddPartner";
import Login from "./components/Login/Login";

import Privacypolicy from "./pages/Home/Privacypolicy";
import Customermanagement from "./pages/customermanagement/Customermanagement"

import Settings from "./pages/Home/Settings";
import Custumerdetailsedit from "./pages/customermanagement/Custumerdetailsedit";
import Addsmalladmins_customer from "./pages/customermanagement/Addsmalladmins_customer";


import Profile from "./pages/Home/Profile";
import ChangePass from "./pages/Home/ChangePass";
import Custumerdetails from "./pages/customermanagement/Custumerdetails";
import Contactus from "./pages/Home/Contactus";
import Notifications from "./pages/Home/Notifications";
import NotificationReply from "./pages/Home/NotificationReply";
import Faq from "./pages/Home/Faq";

import Companymangement from "./pages/companymangement/Companymangement";
import CompanyEarn from "./pages/companymangement/CompanyEarn";

import Companydetailsedit from "./pages/companymangement/Companydetailsedit";
/*****single normal cuctomer Transaction ********/
import AddNormalTransaction from "./pages/Customer_Transaction/AddNormalTransaction";
import AddMoneyTransaction from "./pages/Customer_Transaction/AddMoneyTransaction";
import CustumerTransactionView from "./pages/Customer_Transaction/CustumerTransactionView";
import CustomerTransactiondetails from "./pages/Customer_Transaction/CustomerTransactiondetails";
import CustomerMessagehistories from "./pages/Customer_Transaction/CustomerMessagehistories";
import Investmenthistories from "./pages/Customer_Transaction/Investmenthistories";
import CustomerTransactiondetailsSuccess from "./pages/Customer_Transaction/CustomerTransactiondetailsSuccess";
import CustomerTransactiondetailsFaild from "./pages/Customer_Transaction/CustomerTransactiondetailsFaild";
import CustomerTransactiondetailsPending from "./pages/Customer_Transaction/CustomerTransactiondetailsPending";
import InvestmentAllocatedhistories from "./pages/Customer_Transaction/InvestmentAllocatedhistories";


import AllCustumerTransactionView from "./pages/companymangement/AllCustumerTransactionView";
import CustumerAllocatedView from "./pages/companymangement/CustumerAllocatedView";
import AllCustomerTransactiondetails from "./pages/companymangement/AllCustomerTransactiondetails";
import AllCustomerTransactiondetailsSuccess from "./pages/companymangement/AllCustomerTransactiondetailsSuccess";
import AllCustomerTransactiondetailsPending from "./pages/companymangement/AllCustomerTransactiondetailsPending";



/*****single normal cuctomer investment plan ********/
import SingleCustomerInvestPlanView from "./pages/customer_plan_inverst/SingleCustomerInvestPlanView"
import SingleCustomerPlan from "./pages/customer_plan_inverst/SingleCustomerPlan"
import SingleCustomerPlanActive from "./pages/customer_plan_inverst/SingleCustomerPlanActive"
import SingleCustomerPlanExpired from "./pages/customer_plan_inverst/SingleCustomerPlanExpired"
import SingleCustomerPlansPending from "./pages/customer_plan_inverst/SingleCustomerPlansPending"


/*****investmentplan ********/
import Investmentmanagement from "./pages/investmentmanagement/Investmentmanagement";
import Addplan from "./pages/investmentmanagement/Addplan";
import AddMoreplan from "./pages/investmentmanagement/AddMoreplan";

import InvestmentView from "./pages/investmentmanagement/InvestmentView";
import Investmentedit from "./pages/investmentmanagement/Investmentedit";
import Activeplan from "./pages/investmentmanagement/Activeplan";
import Inactiveplan from "./pages/investmentmanagement/Inactiveplan";
/******customer_investments********/
import CustomerinvestmentsActive from "./pages/customer_investments/CustomerinvestmentsActive";
import CustomerinvestmentsInactive from "./pages/customer_investments/CustomerinvestmentsInactive";
import Customerinvestmentspending from "./pages/customer_investments/Customerinvestmentspending";
import Customerinvestmentsexpired from "./pages/customer_investments/Customerinvestmentsexpired";
import Customerinvestments from "./pages/customer_investments/Customerinvestments";

import Changepasssword from "./components/Login/Changepasssword";
import Forgetpassword from "./components/Login/Forgetpassword";
import Addcompany from "./pages/companymangement/Addcompany";
import CompanyView from "./pages/companymangement/CompanyView";
import Footer from "./components/Footer";
import { Container } from "react-bootstrap";  

import SingleCompanyActiveCustomer from "./pages/SingleCompany_customer/SingleCompanyActiveCustomer"
import SingleCompanyCustomer from "./pages/SingleCompany_customer/SingleCompanyCustomer"
import SingleCompanyInactiveCustomer from "./pages/SingleCompany_customer/SingleCompanyInactiveCustomer"
import InSwanTransactionView from "./pages/InvestedInSwan/InSwanTransactionView"
import AddInSwanTransaction from "./pages/InvestedInSwan/AddInSwanTransaction"
import Investmentinsawn from "./pages/InvestedInSwan/Investmentinsawn";
import InvestmenteUpdate from "./pages/customermanagement/InvestmenteUpdate"
import HistoryLog from "./pages/customermanagement/HistoryLog"
/////////////////////////////////////////////////////////////
import AgreementEnglish from "./pages/Agreement/AgreementEnglish"
import AgreementGujView from "./pages/Agreement/AgreementGujView"
import AgreementEngView from "./pages/Agreement/AgreementEngView"
import AgreementList from "./pages/Agreement/AgreementList"
import AgreementGujarati from "./pages/Agreement/AgreementGujarati";
//////////////////////////////////////////
import "./App.css";


function App() {
  return (
    <React.Fragment>
      
      {/* <Container fluid> */}
        <Router>
          <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgetpassword" element={<Forgetpassword />} />
          <Route path="/changepasssword" element={<Changepasssword />} />

          <Route path="/*" element={
            <>        
              <Sidebar />
          <div className="content" style={{ marginLeft: "235px" }}>
            <Navbar />
            <main className="main" id="top">
              <Container fluid>

              <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/Investment-management" element={<Investmentmanagement />} />
                <Route path="/Addplan" element={<Addplan />} />
                <Route path="/Add-More-plan" element={<AddMoreplan />} />

                <Route path="/InvestmentView" element={<InvestmentView />} />
                <Route path="/InSwan-Transaction-View" element={<InSwanTransactionView />} />
                <Route path="/AddInSwanTransaction" element={<AddInSwanTransaction />} />
                <Route path="/Investmentedit" element={<Investmentedit />} />
                <Route path="/Active-plan" element={<Activeplan />} />
                <Route path="/Inactive-plan" element={<Inactiveplan />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/companymangement" element={<Companymangement />} />
                <Route path="/Company-Earn" element={<CompanyEarn />} />
                <Route path="/Addcompany" element={<Addcompany />} />
                <Route path="/CompanyView" element={<CompanyView />} />
                <Route path="/Companydetailsedit" element={<Companydetailsedit />} />

                <Route path="/Customermanagement" element={<Customermanagement />} />
                <Route path="/Privacypolicy" element={<Privacypolicy />} />
                <Route path="/Contactus" element={<Contactus />} />
                <Route path="/Notifications" element={<Notifications />} />
                <Route path="/NotificationReply" element={<NotificationReply />} />
                <Route path="/Custumer-Allocated-View" element={<CustumerAllocatedView />} />
        
                <Route path="/Custumerdetails" element={<Custumerdetails />} />
                <Route path="/Profile" element={<Profile />} />
                <Route path="/ChangePass" element={<ChangePass />} />

                <Route path="/Custumerdetailsedit" element={<Custumerdetailsedit />} />
                <Route path="/Add-admins-customer" element={<Addsmalladmins_customer />} />

                <Route path="/Customer-investments" element={<Customerinvestments />} />
                <Route path="/Customer-investments-Active" element={<CustomerinvestmentsActive />} />
                <Route path="/Customer-investments-Inactive" element={<CustomerinvestmentsInactive />} />
                <Route path="/Customer-investments-pending" element={<Customerinvestmentspending />} />
                <Route path="/Customer-investments-expired" element={<Customerinvestmentsexpired />} />
                {/* ----------------------------------------------------------------------------------------- */}

                <Route path="/Add-Customer-Transaction" element={<AddNormalTransaction />} />
                <Route path="/add-money" element={<AddMoneyTransaction />} />
                <Route path="/InvestmenteUpdate" element={<InvestmenteUpdate />} />
                <Route path="/Normal-Transaction-View" element={<CustumerTransactionView />} />
                <Route path="/Customer-Transaction-Details" element={<CustomerTransactiondetails />} />
                <Route path="/customer-message-histories" element={<CustomerMessagehistories />} />
                <Route path="/investment-history-logs" element={<Investmenthistories />} />
                <Route path="/Customer-Transactiondetails-credit" element={<CustomerTransactiondetailsSuccess />} />
                <Route path="/Customer-Transactiondetails-debit" element={<CustomerTransactiondetailsPending />} />
                <Route path="/Customer-Transactiondetails-Failds" element={<CustomerTransactiondetailsFaild />} />
                
                <Route path="/All-Customer-Transaction-View" element={<AllCustumerTransactionView />} />
                <Route path="/All-Customer-Transaction-Details" element={<AllCustomerTransactiondetails />} />
                <Route path="/All-Customer-Transactiondetails-credit" element={<AllCustomerTransactiondetailsSuccess />} />
                <Route path="/All-Customer-Transactiondetails-debit" element={<AllCustomerTransactiondetailsPending />} />

                <Route path="/Single-Customer-InvestPlan-View" element={<SingleCustomerInvestPlanView />} />
                <Route path="/Single-Customer-Plan" element={<SingleCustomerPlan />} />
                <Route path="/Single-Customer-Plan-Active" element={<SingleCustomerPlanActive />} />
                <Route path="/Single-Customer-Plan-Pending" element={<SingleCustomerPlansPending />} />
                <Route path="/Single-Customer-Plan-Expired" element={<SingleCustomerPlanExpired />} />
                
               

             
             
              {/* -------------------------------------------------------------------------------------------------- */}
              <Route path="/Company-Active-Normal-Customer" element={<SingleCompanyActiveCustomer />} />
              <Route path="/Company-Inactive-Normal-Customer" element={<SingleCompanyInactiveCustomer />} />
              <Route path="/Company-Normal-Customer" element={<SingleCompanyCustomer />} />

            
              <Route path="/History-Log" element={<HistoryLog />} />

                <Route path="/aboutus" element={<Aboutus />} />            
                <Route path="/Investmentinsawn" element={<Investmentinsawn />} />            
                <Route path="/PartnerTransaction" element={<PartnerTransition />} />            
                <Route path="/Partner-Update" element={<PartnerUpdate />} />            
                <Route path="/Partner-Transaction-View" element={<PartnerTransactionView />} />            
                <Route path="/Add-Partner-Transaction" element={<AddPartnerTransaction />} />            
                <Route path="/Partner-Transaction-details" element={<PartnerTransactiondetails />} />            
                <Route path="/Partner-Details" element={<PartnerDetails />} />            
                <Route path="/AddPartner" element={<AddPartner />} />            
                <Route path="/Agreement-Eng-View" element={<AgreementEngView />} />            
                <Route path="/Agreement-Guj-View" element={<AgreementGujView />} />            
                <Route path="/Agreement-Gujarati" element={<AgreementGujarati />} />            
                <Route path="/AgreementList" element={<AgreementList />} /> 
                <Route path="/Agreement-English" element={<AgreementEnglish />} />
                <Route path="/Investment-Allocated-histories" element={<InvestmentAllocatedhistories />} />
           
                <Route path="/Settings" element={<Settings />} />

                <Route
                  path="/termsandcondition"
                  element={<Termsandcondition />}
                />
            
           
              </Routes>
              </Container>
            </main>
          </div>
          <Footer />
          </>
        }/>
        </Routes>
              
        </Router>
      {/* </Container> */}
    </React.Fragment>
  );
}
export default App;
