import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { GiTakeMyMoney } from "react-icons/gi";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Swal from 'sweetalert2';
export default function Customerinvestments() {

  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State to hold the search term
  const navigate = useNavigate();
  const custumerdetails_id = localStorage.getItem("custumerdetails");
  const [Allcompany, setCompany] = useState();
  const [Active, setActive] = useState();
  const [pending, setPending] = useState();
  const [Expired, setExpired] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const fetchData = async () => {
    try {
      setLoading(true);

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("customer_id");
    

      const formData = new FormData();
      formData.append("cust_id",broker_id);
      formData.append("status", '1');

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}customer-swan-wealth-investments-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data.data;
      const data1 = response.data.image_url;

      setbaseurl(data1);
      setCompany(response.data.totaluserinvestments);
      setActive(response.data.totalActiveuserinvestments);
      setPending(response.data.totaluserinvestmentspendings);
      setExpired(response.data.totalexpiredinvestmentplans);
      setData(data);
      console.warn("data",data);
      setLoading(false);

    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const filteredData = data
  ? data.filter((item) =>
    item.customer_name &&
  item.customer_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  : [];

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const AprovedHandlechange = (cust_id, invest_plain_id, company_id,invest_id) => {
    handleApproved(cust_id, invest_plain_id, company_id,invest_id);
    console.warn(cust_id, invest_plain_id, company_id)
  };
  const handleApproved = async (cust_id, invest_plain_id, company_id,invest_id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to approve this plan?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: 'Yes, approve it!',
      cancelButtonText: 'cancel!',
    });
  
    if (result.isConfirmed) {
      try {
        const token = localStorage.getItem("bear_token");
        const broker_id = localStorage.getItem("broker_id");
  
        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("cust_id", cust_id);
        formData.append("invest_plain_id", invest_plain_id);
        formData.append("company_id", company_id);
        formData.append("invest_id", invest_id);
  
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}approve-swan-wealth-customers-plans`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
          },
          data: formData
        };
  
        const response = await axios(config);
        const data = response.data;
  
        if (data.status === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: "Plan Activated Successfully!",
            color:"white",
            background: 'transparent',
            // timer: 200
          });

          fetchData();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "Unexpected response from the server.",
            // timer: 2000
          });
   
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "An error occurred while processing your request.",
          // timer: 2000
        });

      }
    }
  };
 
  const handlePlanViewCustomerClick = (cust_id,invest_plain_id,invest_id) => {
    localStorage.setItem("cust_idPlanView", cust_id);
    localStorage.setItem("invest_plain_idPlanView", invest_plain_id);
    localStorage.setItem("invest_idPlanView", invest_id);
    navigate("/Single-Customer-InvestPlan-View");
  };
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h4 className="mb-0">Customer Investments Plan Analytics</h4>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Home" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-3 mb-3">
        <div className="col-md-3 col-xxl-3">
          <Link to='/Customer-investments'className="linkWithoutUnderline active">
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 d-flex align-items-center">Total</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <GiTakeMyMoney className="fs-4 text-primary" />
                  </div>
                  <div className="col ps-0">
                    <h5 className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                      {Allcompany}
                    </h5>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3 col-xxl-3">
          <Link to='/Customer-investments-Active' className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">Active</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <GiTakeMyMoney className="fs-4 text-success" />
                  </div>
                  <div className="col ps-0">
                    <h5 className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {Active}
                    </h5>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3 col-xxl-3">
        <Link to='/Customer-investments-pending'className="linkWithoutUnderline">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2">Pending</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <GiTakeMyMoney className="fs-4 text-warning" />
                </div>
                <div className="col ps-0">
                  <h5 className="font-sans-serif lh-1 mb-1 fs-5 text-warning">
                    {pending}
                  </h5>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
        {/* <div className="col-md-3 col-xxl-3">
          <Link to='/Customer-investments-Inactive' className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">Inactive</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <GiTakeMyMoney className="fs-4 text-secondary" />
                  </div>
                  <div className="col ps-0">
                    <h5 className="font-sans-serif lh-1 mb-1 fs-5 text-secondary">
                      {Active}
                    </h5>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div> */}
        

        <div className="col-md-3 col-xxl-3">
        <Link to='/Customer-investments-expired'className="linkWithoutUnderline">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2">Expired</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <GiTakeMyMoney className="fs-4 text-danger" />
                </div>
                <div className="col ps-0">
                  <h5 className="font-sans-serif lh-1 mb-1 fs-5 text-danger">
                    {Expired}
                  </h5>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
      </div >
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />

        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h5 className="mb-0">Customer Investment Plan List</h5>
            </div>
          </div>
        </div>
       
      </div>
      <div className="card mb-3">
     
 <div className="card-header justify-content-between d-flex border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <form>
            
              <div className="input-group input-search-width">
                <input
                  className="form-control form-control-sm shadow-none search"
                  type="search"
                  placeholder="Search by name"
                  aria-label="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {/* <button className="btn btn-sm btn-outline-secondary border-300 hover-border-secondary">
                  <span className="fa fa-search fs-10 text-dark" />
                </button> */}
              </div>
            </form>
          </div>

        </div>
        <div className="card-body p-0">
          <div className="table-responsive">
          <table className="table table-sm mb-0 table-dashboard fs--1">
  <thead className="bg-200 text-900">
    <tr>
      <th className="sort">Sr.</th>
      <th className="sort">Name</th>
      <th className="sort"> Email</th> 
      <th className="sort">Company </th>
      <th className="sort">Plan Name</th>
      <th className="sort"> Duration</th>
      {/* <th className="sort"> Duration Type</th> */}
      <th className="sort"> Interest Rate</th>
      <th className="sort">Status</th>
      <th className="sort">Action</th>
    </tr>
  </thead>
  <tbody className="list  small-font" id="table-ticket-body ">
    {paginatedData && paginatedData.length > 0 ? (
      paginatedData.map((item, index) => (
        <tr key={index}>
          <td >{startIndex + index + 1}</td>
          <td className="capitalize">{item.customer_name}</td>
          <td><a href={`mailto:${item.email}`}>{item.email}</a></td>
              
          <td className="capitalize">{item.company_name}</td>
          <td className="capitalize">{item.plan_name}</td>
          <td>{item.plan_duration} {item.plan_duration_type}</td>
          <td>{item.interest_rate} %</td>
          <td>
  <span className={`badge ${item.plan_status === 'approve' && item.is_expired === 'expired' ? 'bg-danger' :
                          item.plan_status === 'pending' && item.is_expired === '' ? 'bg-warning' :
                          item.plan_status === 'approve' && item.is_expired === 'active' ? 'bg-success' :
                          'bg-secondary'}`}>
    {item.plan_status === 'approve' && item.is_expired === 'expired' ? 'Expired' :
     item.plan_status === 'pending' && item.is_expired === '' ? 'Pending' :
     item.plan_status === 'approve' && item.is_expired === 'active' ? 'Approved' :
     'Unknown'}
  </span>
</td>
          <td>
            {item.plan_status !== 'approve' && item.is_expired !== 'expired' ? (
              <>
               <OverlayTrigger
                 key="Activated"
                 placement="top"
                
                 overlay={<Tooltip id={`Activated`}>Activated Now</Tooltip>}
             >
               <button
                  onClick={() => AprovedHandlechange(item.cust_id, item.invest_plain_id, item.company_id,item.invest_id)}
                 style={{ "--i": "#27bcfd" }}
                 className="btn bg-primary  action_button"
               >
                 <FaCheckCircle className="color_link text-light"  />
               </button>
             </OverlayTrigger>
            
              </>
            ) : item.plan_status === 'approve' && item.is_expired === 'active' ? (
              <>
              <OverlayTrigger
              key="View"
              placement="top"
             
              overlay={<Tooltip id={`View`}>View</Tooltip>}
            >
              <button
                onClick={() => handlePlanViewCustomerClick(item.cust_id, item.invest_plain_id,item.invest_id
                )}
                style={{ "--i": "#27bcfd" }}
                className="btn bg-success  action_button"
              >
                <FaEye className="color_link text-light"  />
              </button>
            </OverlayTrigger>
            
              </>
            ) : null}
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="9" className="text-center py-4">
          No data found.
        </td>
      </tr>
    )}
  </tbody>
</table>

          </div>
          {totalPages > 1 && (
            <div className="pagination mt-3 mb-3 mx-3">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber + 1}
                  className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                    ? "btn-light text-primary"
                    : "btn-light"
                    }`}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          )}
        </div>


      </div>
   
    </>
  );
}
