import React, { useState, useEffect } from "react";
import proifle from "../assets/img/team/3-thumb.png";
import { IoIosNotifications } from "react-icons/io";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import userprofile from "../assets/img/team/avatar.png";
const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [BaseUrl, setBaseUrl] = useState(null);
  const broker_id = localStorage.getItem("broker_id");
  const browserId = localStorage.getItem("browserId");
  const token = localStorage.getItem("bear_token");
  const [AproveStatus, setAproveStatus] = useState();
  const [AproveCount, setAproveCount] = useState(null);
  const [formData1, setFormData1] = useState();
  // const profileimage = localStorage.getItem("profileimage");
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    address: "",
    phone: "",
    altername_phone: "",
    profile: null,
  });
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setButtonClicked(true);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const user_id = localStorage.getItem("broker_id");

    const token = localStorage.getItem("bear_token");
    if (user_id == null) {
      navigate("/");
      localStorage.clear();
    }
    if (token == null) {
      navigate("/");
      localStorage.clear();
    }
  }, []);
  const logout = (e) => {
    e.preventDefault();

    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, log out!',
        color:"white",
        background: 'transparent'
    }).then((result) => {
        if (result.isConfirmed) {
            localStorage.clear();
            navigate("/");
        }
    });
};

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 3) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    getProfile();
    // getAutCheck();
    shareStatus();
    shareStatuslist();
  }, []);

  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", broker_id);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}show-swan-wealth-Profile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
     if (response.data.status === 0) {
      localStorage.clear();
      navigate("/");
    }
 
      const data = response.data.data;
      
      const baseurl = response.data.image_url;

      setBaseUrl(baseurl);
      localStorage.setItem("baseurl", baseurl);
      setFormData(data[0]);
      setFormData1(data[0].first_name);
     
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      console.warn("Error fetching user profile:", errorMessage);

      if (errorMessage === "Unauthenticated") {
      } else {
        console.error("Error fetching user profile:", error);
      }
    }
  };

  const NotificationSeen = async () => {
  
    try {
      const formData = new FormData();
      formData.append("user_id", broker_id);

      const token = localStorage.getItem("bear_token");

    

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}super-admins-sent-to-wealth-notification-seen`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      shareStatus();
     
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  const getAutCheck = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", broker_id);

      const token = localStorage.getItem("bear_token");

      if (!broker_id || !token) {
        localStorage.clear();
        navigate("/");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}swan-wealth-check-match-token`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (
        response.data.browser_id !== browserId ||
        response.data.api_token !== token
      ) {
        localStorage.clear();
        navigate("/");
      }
      localStorage.setItem("checkapi_token", response.data.api_token);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const shareStatus = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", broker_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}super-admins-sent-to-swan-wealth-notification-count`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);

   
      setAproveCount(response.data.data);
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };
  const shareStatuslist = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", broker_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}super-admins-sent-toswan-wealth-notification-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
console.warn("response.data.data",response.data.data)
      setAproveStatus(response.data.data);
     
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };
  const formatDate = (dateString) => {
    // Split the input date string
    const [day, month, year] = dateString.split('-').map(Number);
    
    // Create a new Date object
    const date = new Date(year, month - 1, day); // Months are 0-based in JavaScript Date
    
    // Get hours and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes();
    
    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    
    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Hour '0' should be '12'
    
    // Format minutes with leading zero if needed
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    
    // Format the date as MM/DD/YYYY h:mm AM/PM
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${hours}:${formattedMinutes} ${ampm}`;
  };
  const removeHtmlTags = (text) => {
    // Check if text is null or undefined, return an empty string or handle it accordingly
    if (!text) {
      return ''; // Return an empty string if the text is null or undefined
    }
    return text.replace(/<[^>]*>/g, '');
  };
  
  // Usage example
  const result = removeHtmlTags(null); // This will now return an empty string instead of throwing an error
  console.log(result); // Output: ''

  
  return (
    <nav
      className={`navbar navbar-light navbar-top navbar-expand-lg ${
        isSticky ? "navbar-glass-shadow" : ""
      }`}
    >
      <div className="container ">
        <div className="w-100">
          <div className="d-flex flex-between-center align-items-center container">
            <button
              className="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarDoubleTop"
              aria-controls="navbarDoubleTop"
              aria-expanded="false"
              aria-label="Toggle Navigation"
            >
              <span className="navbar-toggle-icon">
                <span className="toggle-line" />
              </span>
            </button>
            <div className="marquee-container text-center w-100">
              <h3 className="text-info "><span className="text-primary">Welcome To :</span> Swan Wealth Dashboard <span className="capitalizeall">({formData1})</span></h3>
            </div>
            {/* <ul className="navbar-nav align-items-center d-none d-lg-block">
              <li className="nav-item">
                <div
                  className="search-box"
                  data-list='{"valueNames":["title"]}'
                >
                  <form
                    className="position-relative"
                    data-bs-toggle="search"
                    data-bs-display="static"
                  >
                    <input
                      className="form-control search-input fuzzy-search"
                      type="search"
                      placeholder="Search..."
                      aria-label="Search"
                    />
                  </form>
                  <div
                    className="btn-close-falcon-container position-absolute end-0 top-50 translate-middle shadow-none"
                    data-bs-dismiss="search"
                  >
                    <button
                      className="btn btn-link btn-close-falcon p-0"
                      aria-label="Close"
                    />
                  </div>
                  <div className="dropdown-menu border font-base start-0 mt-2 py-0 overflow-hidden w-100">
                  </div>
                </div>
              </li>
            </ul> */}
            <ul className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center" onClick={NotificationSeen}>
              <li className="position-relative">
                <div
                  onClick={toggleDropdown}
                  className={buttonClicked ? "clicked" : "dfsdf"}
                  style={{ position: "relative" }} // Ensure the badge is positioned correctly
                >
                  <IoIosNotifications className="icon_size text-dark" />
                  {AproveCount && AproveCount ? (
                    <span
                      className="badge rounded-pill"
                      style={{
                        backgroundColor: "red",
                        position: "absolute",
                        top: "-4px",
                        right: "-4px",
                      }}
                    >
                      {AproveCount}
                    </span>
                  ) : (
                    <span
                      className="badge rounded-pill"
                      style={{
                        backgroundColor: "red",
                        position: "absolute",
                        top: "-4px",
                        right: "-4px",
                      }}
                    >
                      0
                    </span>
                  )}
                </div>
           
                {isDropdownOpen &&AproveCount &&  AproveCount && (
                  <ul
                    className={`dropdown-menu dropdown-caret dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg ${
                      buttonClicked ? "d-block" : ""
                    }`}
                  >
                    <li className="nav-item dropdown">
                      <div className="card card-notification shadow-none">
                        <div className="card-header">
                          <div className="row justify-content-between align-items-center">
                            <div className="col-auto">
                              <h6 className="card-header-title mb-0">
                                Notifications
                              </h6>
                            </div>
                            <div className="col-auto ps-0 ps-sm-3">
                              <a className="card-link fw-normal" href="#">
                                {/* Mark all as read */}
                              </a>
                            </div>
                          </div>
                        </div>
                        
                        <div className="card-body">
                          <div className="list-group-item">
                            <a
                              className="notification notification-flush notification-unread"
                              href="#!"
                            >
                              <div className="notification-avatar mx-1">
                                {/* <div className="avatar avatar-2xl me-1">
                                  <img
                                    className="rounded-circle"
                                    src="../../assets/img/team/1-thumb.png"
                                    alt=""
                                  />
                                </div> */}
                              </div>
                              {AproveCount && AproveCount > 0 ? (
                                AproveStatus && AproveStatus.length > 0 ? (
                                  AproveStatus.map((message, index) => (
                                    <div key={index} className="notification-body">
                                      <p className="mb-1 capitalize" >
                                        {index + 1}. {removeHtmlTags(message.message)}
                                      </p>
                                      <span className="notification-time">
                                        <span
                                          className="me-2"
                                          role="img"
                                          aria-label="Emoji"
                                        >
                                          💬
                                        </span>
                                        {formatDate(message.date)}
                                        {/* Just now */}
                                      </span>
                                    </div>
                                  ))
                                ) : (
                                  <p className="mb-1">No new messages</p>
                                )
                              ) : (
                                <p className="mb-1">No new messages</p>
                              )}
                            </a>
                          </div>
                        </div>
                        <div
                          className="scrollbar-overlay"
                          style={{ maxHeight: "19rem" }}
                        >
                          <div className="list-group list-group-flush fw-normal fs-10">
                            {/* Notification items */}
                          </div>
                        </div>
                        <div className="card-footer text-center border-top">
                          <Link className="card-link d-block" to='/Notifications'>
                            View all
                          </Link>
                        </div>
                      </div>
                    </li>
                  </ul>
                )}
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link pe-0 ps-2"
                  id="navbarDropdownUser"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="avatar avatar-xl">
                    <img
                      className="rounded-circle"
                      //  src={`https://broker.swaninvestment.in/API/public/backend/uploads/small_admins/${profileimage}`}
                      src={
                        formData.profile
                          ? `${BaseUrl}/${formData.profile}`
                          : userprofile
                      }
                      alt=""
                    />
                  </div>
                </Link>
                <div
                  className="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end py-0"
                  aria-labelledby="navbarDropdownUser"
                >
                  <div className="bg-white dark__bg-1000 rounded-2 py-2">
                    <Link className="dropdown-item capitalize" to="/Profile">
                      Profile ({formData1})
                    </Link>
                    {/* <a className="dropdown-item" href="#">
                    Profile 
                    </a> */}
                    <Link className="dropdown-item" to="/ChangePass">
                      Change Password
                      </Link>
                    <a className="dropdown-item" onClick={logout}>
                      Logout
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ToastContainer />
    </nav>
  );
};

export default Navbar;
