import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaEye } from "react-icons/fa";
import optional from "../../assets/img/optional.jpg";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";

import "react-datepicker/dist/react-datepicker.css";
import { Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { parse, isWithinInterval } from "date-fns";
export default function Investmenthistories() {
  const [data, setData] = useState([]);
  const [RemainsBalance, setRemainsBalance] = useState("0");
  const navigate = useNavigate();
  const customer_id = localStorage.getItem("customer_id");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [show, setShow] = useState(false);
  const [baseurl, setbaseurl] = useState(null);
  const [fromDate, setFromDate] = useState(null); // Date object
  const [toDate, setToDate] = useState(null);
  const [allData, setAllData] = useState([]);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  useEffect(() => {
    fetchData();
  }, []);
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };
  const fetchData = async () => {
    try {
      // setLoading(true);

      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("cust_id", customer_id);
      formData.append("compay_id", "1");
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-wealth-customer-transactions-histories`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;

      setbaseurl(response.data.trnximageUrl);
      setData(response.data.data);
      setAllData(response.data.data);
      setRemainsBalance(response.data.user_credits.toFixed(0));
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false);
    }
  };
  const filteredData = data
    ? data.filter((item) =>
        item.transaction_id.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // const handleviveCustomerClick = (cust_id) => {
  //   handleShow();
  // };
  const handleviveCustomerClick = (transactionid) => {
    localStorage.setItem("transactionid", transactionid);
    navigate("/Custumer-Allocated-View");
  };
  const parseDateString = (dateString) => {
    return parse(dateString, "dd-MM-yyyy", new Date());
  };
  const handleSearch = () => {
    if (!fromDate || !toDate) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please choose a valid date!",
        // timer: 2000
      });
    }
    if (fromDate && toDate) {
      const filteredTransactions = allData.filter((item) => {
        const transactionDate = parseDateString(item.transaction_date);
        return transactionDate >= fromDate && transactionDate <= toDate;
      });
      setData(filteredTransactions);
    } else {
      setData(allData);
    }
  };

  const handleRefresh = () => {
    setFromDate("");
    setToDate("");
    setSearchTerm("");
    fetchData();
  };
  // /Custumer-Allocated-View
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">
                Customer Allocated/UnAllocated Funds History
              </h3>
            </div>
            <div className="col-lg-3 text-end">
              <h3 class="mb-0 text-success">
                {" "}
                <MdOutlineAccountBalanceWallet className="fs-4 text-success" />{" "}
                {RemainsBalance}
              </h3>
            </div>
            <div className="col-lg-1 text-end">
              <Link to="/Custumerdetails" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-3">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Transaction Id"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-9 text-end">
              <Row>
                <div className="col-md-4 form_details">
                  <div className="form-group local-forms">
                    <label className="form-label">From Date</label>

                    <DatePicker
                      className="form-control"
                      selected={fromDate}
                      onChange={handleFromDateChange}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD-MM-YYYY"
                      customInput={
                        <InputMask mask="99/99/9999" maskChar={null} />
                      }
                    />
                  </div>
                </div>
                <div className="col-md-4 form_details">
                  <div className="form-group local-forms">
                    <label className="form-label">To Date</label>

                    <DatePicker
                      className="form-control"
                      selected={toDate}
                      onChange={handleToDateChange}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD-MM-YYYY"
                      customInput={
                        <InputMask mask="99/99/9999" maskChar={null} />
                      }
                    />
                  </div>
                </div>

                <div className="col-md-4 d-flex gap-1 justify-content-evenly  align-item-space-between">
                  {/* <div className="mb-3"> */}
                  <button className="btn btn-primary " onClick={handleSearch}>
                    Search
                  </button>
                  <button className="btn btn-dark " onClick={handleRefresh}>
                    Refresh
                  </button>
                  {/* <button className="btn btn-dark " onClick={handleDownload}>
                  <FaCloudDownloadAlt className="fs-4 text-light" />
                  </button> */}
                  {/* <MdArrowOutward className="" /> */}
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-800 sort align-middle">Sr.</th>
                  <th>Transaction ID</th>
                  <th>Customer Name</th>
                  {/* <th>Fund From</th> */}

                  <th>Payment Mode</th>
                  <th>Payment Type</th>
                  <th>Transaction Amount</th>
                  <th>Expected Profit</th>
                  <th>Type</th>
                  <th>Transaction Date</th>
                  {/* <th>Remarks</th> */}
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    <tr key={index} className="capitalize">
                      <td>{startIndex + index + 1}</td>
                      <td>{item.transaction_id}</td>
                      <td>{item.CustName || "NA"}</td>
                      {/* <td>{item.company_name}</td> */}
                      <td>{`${item.payment_mode} (${item.payment_mode_type})`}</td>
                      <td
                        style={{
                          color:
                            item.payment_type.toLowerCase() === "debit"
                              ? "red"
                              : item.payment_type.toLowerCase() === "credit"
                              ? "green"
                              : "black",
                        }}
                      >
                        {item.payment_type}
                      </td>
                      <td>{item.transaction_amount}</td>
                      <td
                        style={{
                          color:
                            item.expected_profit < 0
                              ? "red"
                              : item.expected_profit > 0
                              ? "green"
                              : "black",
                        }}
                      >
                        {item.expected_profit || 0}
                      </td>
                      <td>
                        {item.type
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </td>
                      <td>{item.created}</td>
                      {/* <td>{item.type || '-'}</td> */}
                      {/* <td>{item.remark || 'No remarks'}</td> */}
                      <td>
                        <div className="d-flex gap-1 action_button">
                          <OverlayTrigger
                            key="View"
                            placement="top"
                            overlay={<Tooltip id={`View`}>View</Tooltip>}
                          >
                            <button
                              onClick={() => handleviveCustomerClick(item.id)}
                              style={{ "--i": "#27bcfd" }}
                            >
                              <FaEye className="color_link" />
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        size="md"
                        fullscreen="md-down"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Images</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row text-center">
                            {" "}
                            {/* Centers content horizontally */}
                            <div className="col-md-12">
                              <strong>Transaction Image</strong>
                              <img
                                src={
                                  item.trnx_image
                                    ? `${baseurl}/${item.trnx_image}`
                                    : optional
                                }
                                alt="Transaction Image"
                                className="img-fluid my-2 hoverable"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "400px",
                                }} // Limits image size
                              />
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {totalPages > 1 && (
            <div className="pagination mt-3 mb-3 mx-3">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber + 1}
                  className={`btn btn-sm mx-1 ${
                    currentPage === pageNumber + 1
                      ? "btn-light text-primary"
                      : "btn-light"
                  }`}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
