import React, { useState, useEffect } from "react";
import proifle from "../../../src/assets/img/gallery/2.jpg";
import { IoIosNotifications } from "react-icons/io";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaRegEdit, FaEye, FaSearch } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast, ToastContainer } from "react-toastify";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
const Home = () => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   getAutCheck();
  // }, []);
  const broker_id = localStorage.getItem("broker_id");
  const browserId = localStorage.getItem("browserId");
  const token = localStorage.getItem("bear_token");

  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);

  const [baseurl, setbaseurl] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-wealth-customer-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;

      setData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (searchTerm) {
      const results = data.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(results);
    } else {
      setFilteredData(data);
    }
  }, [searchTerm, data]);

  const getAutCheck = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", broker_id);

      const token = localStorage.getItem("bear_token");

      if (!broker_id || !token) {
        localStorage.clear();
        navigate("/");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}swan-wealth-check-match-token`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (
        response.data.browser_id !== browserId ||
        response.data.api_token !== token
      ) {
        localStorage.clear();
        navigate("/");
      }
      localStorage.setItem("checkapi_token", response.data.api_token);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const filteredDatas = datas
    ? data.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredDatas.slice(startIndex, endIndex);
  const SearchCostomer = (cust_id) => {
    localStorage.setItem("customer_id", cust_id);
    navigate("/Custumerdetails");
  };

  return (
    <div className="page">
      <>
        <div className="row mb-3">
          <div className="col-md-12">
            <div className="card bg-100 shadow-none border">
              <div className="row gx-0 flex-between-center">
                <div className="col-sm-auto d-flex align-items-center">
                  <img
                    className="ms-n2"
                    src="./assets/img/illustrations/crm-bar-chart.png"
                    alt=""
                    width={90}
                  />
                  <div>
                    <h6 className="text-primary fs-10 mb-0">Welcome to </h6>
                    <h4 className="text-primary fw-bold mb-0">
                      Swan <span className="text-info fw-medium">Wealth</span>
                    </h4>
                  </div>
                  <img
                    className="ms-n4 d-md-none d-lg-block"
                    src="./assets/img/illustrations/crm-line-chart.png"
                    alt=""
                    width={150}
                  />
                </div>
                <div className="col-md-auto p-3">
                  <form className="row align-items-center g-3">
                    <div className="col-auto">
                      <h6 className="text-700 mb-0">Showing Data For: </h6>
                    </div>
                    <div className="col-md-auto position-relative">
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {searchTerm && (
                        <ul className="list-group search_list">
                          {filteredData.map((item) => (
                            <li
                              key={item.id}
                              className="list-group-item"
                              onClick={() => SearchCostomer(item.cust_id)}
                            >
                              {item.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col-md-6 col-xxl-3">
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h6 className="mb-0 mt-2 d-flex align-items-center">
                  Yearly Client
                  <span
                    className="ms-1 text-400"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Calculated according to last week's sales"
                  >
                    <span
                      className="far fa-question-circle"
                      data-fa-transform="shrink-1"
                    />
                  </span>
                </h6>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row">
                  <div className="col">
                    <p className="font-sans-serif lh-1 mb-1 fs-5">$47K</p>
                    <span className="badge badge-subtle-success rounded-pill fs-11">
                      +3.5%
                    </span>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xxl-3">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h6 className="mb-0 mt-2">Total Client</h6>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col-auto align-self-end">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                      58.4K
                    </div>
                    <span className="badge rounded-pill fs-11 bg-200 text-primary">
                      <span className="fas fa-caret-up me-1" />
                      13.6%
                    </span>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-lg-12">
            <div
              className="card"
              id="TableCrmRecentLeads"
              data-list='{"valueNames":["name","email","status"],"page":8,"pagination":true}'
            >
              <div className="card-header d-flex flex-between-center flex-wrap gap-2 py-2">
                <h6 className="mb-0">Recent Leads</h6>
                <div className="d-none" id="table-recent-leads-actions">
                  <div className="d-flex">
                    <select
                      className="form-select form-select-sm"
                      aria-label="Bulk actions"
                    >
                      <option selected="">Bulk actions</option>
                      <option value="Delete">Delete</option>
                      <option value="Archive">Archive</option>
                    </select>
                    <button
                      className="btn btn-falcon-default btn-sm ms-2"
                      type="button"
                    >
                      Apply
                    </button>
                  </div>
                </div>
                <div id="table-recent-leads-replace-element">
                  <div className="dropdown font-sans-serif btn-reveal-trigger">
                    <button
                      className="btn btn-link text-600 btn-sm dropdown-toggle dropdown-caret-none btn-reveal"
                      type="button"
                      id="recent-leads-header-dropdown"
                      data-bs-toggle="dropdown"
                      data-boundary="viewport"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="fas fa-ellipsis-h fs-11" />
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-end border py-2"
                      aria-labelledby="recent-leads-header-dropdown"
                    >
                      <a className="dropdown-item" href="#!">
                        View
                      </a>
                      <a className="dropdown-item" href="#!">
                        Export
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item text-danger" href="#!">
                        Remove
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body px-0 py-0">
                <div className="table-responsive scrollbar">
                  <table className="table fs-10 mb-0">
                    <thead className="bg-200 w-100">
                      <tr>
                        <th className="white-space-nowrap align-middle py-0">
                          {/* <div className="form-check mb-0 d-flex align-items-center">
                    </div> */}
                        </th>
                        <th
                          className="text-800 sort align-middle"
                          data-sort="name"
                        >
                          Name
                        </th>
                        <th
                          className="text-800 sort align-middle"
                          style={{ width: "54%" }}
                          data-sort="email"
                        >
                          Email and Phone
                        </th>
                        <th
                          className="text-800 sort align-middle"
                          data-sort="status"
                        >
                          Status
                        </th>
                        <th className="text-800 sort align-middle text-end">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="list" id="table-recent-leads-body">
                      <tr className="hover-actions-trigger btn-reveal-trigger hover-bg-100 ">
                        <td className="align-middle" style={{ maxWidth: 30 }}>
                          <div className="form-check mb-0"></div>
                        </td>
                        <td className="align-middle white-space-nowrap">
                          <a
                            className="text-800"
                            href="../pages/user/profile.html"
                          >
                            <div className="d-flex align-items-center">
                              <div className="avatar avatar-xl">
                                <img
                                  className="rounded-circle"
                                  src={proifle}
                                  alt=""
                                />
                              </div>
                              <h6 className="mb-0 ps-2 name">Kerry Ingram</h6>
                            </div>
                          </a>
                        </td>
                        <td className="align-middle white-space-nowrap text-primary email">
                          <a href="mailto:john@gmail.com">john@gmail.com</a>
                        </td>
                        <td className="align-middle white-space-nowrap">
                          <small className="badge fw-semi-bold rounded-pill status badge-subtle-primary">
                            {" "}
                            New Lead
                          </small>
                        </td>
                        <td className="align-middle priority pe-4">
                          <div className="d-flex gap-1 action_button">
                            <OverlayTrigger
                              key="Edit"
                              placement="top"
                              overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                            >
                              <button
                                // onClick={() => handleClickEditCompanyId(item.id)}
                                style={{ "--i": "#2c7be5" }}
                              >
                                {" "}
                                <FaRegEdit className="color_link" />
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key="View"
                              placement="top"
                              overlay={<Tooltip id={`View`}>View</Tooltip>}
                            >
                              <button
                                // onClick={() => handleClickCompanyId(item.id)}
                                style={{ "--i": "#27bcfd" }}
                              >
                                <FaEye className="color_link" />
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key="Delete"
                              placement="top"
                              overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                            >
                              <button
                                style={{ "--i": "red" }}
                                // onClick={() => Delete(item.id)}
                              >
                                {" "}
                                <MdDelete className="color_link" />
                              </button>
                              {/* <Link style={{ '--i': 'red' }} onClick={() => {
                          Delete(item.cust_id);
                        }}> <MdDelete className="color_link" /></Link> */}
                            </OverlayTrigger>

                            {/* <OverlayTrigger
                          key="Delete"
                          placement="top"
                          overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                        >
                          <Link to="#"> <MdDelete className="color_link" /></Link>
                        </OverlayTrigger> */}

                            {/* <button onClick={() => handleClickCompanyId(item.id)}>View Details</button> */}

                            {/* <OverlayTrigger
                          key="Update"
                          placement="top"
                          overlay={<Tooltip id={`Update`}>Update</Tooltip>}
                        >
                          <Link to="/Viewdetailsupdate"><IoMdAdd className="color_link" />
                          </Link>
                        </OverlayTrigger> */}
                          </div>
                        </td>
                        {/* <td className="align-middle white-space-nowrap text-end position-relative">
                    <div className="hover-actions bg-100">
                      <button className="btn icon-item rounded-3 me-2 fs-11 icon-item-sm">
                        <span className="far fa-edit" />
                      </button>
                      <button className="btn icon-item rounded-3 me-2 fs-11 icon-item-sm">
                        <span className="far fa-comment" />
                      </button>
                    </div>
                    <div className="dropdown font-sans-serif btn-reveal-trigger">
                      <button
                        className="btn btn-link text-600 btn-sm dropdown-toggle dropdown-caret-none btn-reveal-sm transition-none"
                        type="button"
                        id="crm-recent-leads-0"
                        data-bs-toggle="dropdown"
                        data-boundary="viewport"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="fas fa-ellipsis-h fs-11" />
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-end border py-2"
                        aria-labelledby="crm-recent-leads-0"
                      >
                        <a className="dropdown-item" href="#!">
                          View
                        </a>
                        <a className="dropdown-item" href="#!">
                          Export
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item text-danger" href="#!">
                          Remove
                        </a>
                      </div>
                    </div>
                  </td> */}
                      </tr>
                      <tr className="hover-actions-trigger btn-reveal-trigger hover-bg-100">
                        <td className="align-middle" style={{ maxWidth: 30 }}>
                          <div className="form-check mb-0"></div>
                        </td>
                        <td className="align-middle white-space-nowrap">
                          <a
                            className="text-800"
                            href="../pages/user/profile.html"
                          >
                            <div className="d-flex align-items-center">
                              <div className="avatar avatar-xl">
                                <img
                                  className="rounded-circle"
                                  src={proifle}
                                  alt=""
                                />
                              </div>
                              <h6 className="mb-0 ps-2 name">Bradie Knowall</h6>
                            </div>
                          </a>
                        </td>
                        <td className="align-middle white-space-nowrap text-primary email">
                          <a href="mailto:bradie@mail.ru">bradie@mail.ru</a>
                        </td>
                        <td className="align-middle white-space-nowrap">
                          <small className="badge fw-semi-bold rounded-pill status badge-subtle-primary">
                            {" "}
                            New Lead
                          </small>
                        </td>
                        <td className="align-middle priority pe-4">
                          <div className="d-flex gap-1 action_button">
                            <OverlayTrigger
                              key="Edit"
                              placement="top"
                              overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                            >
                              <button
                                // onClick={() => handleClickEditCompanyId(item.id)}
                                style={{ "--i": "#2c7be5" }}
                              >
                                {" "}
                                <FaRegEdit className="color_link" />
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key="View"
                              placement="top"
                              overlay={<Tooltip id={`View`}>View</Tooltip>}
                            >
                              <button
                                // onClick={() => handleClickCompanyId(item.id)}
                                style={{ "--i": "#27bcfd" }}
                              >
                                <FaEye className="color_link" />
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key="Delete"
                              placement="top"
                              overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                            >
                              <button
                                style={{ "--i": "red" }}
                                // onClick={() => Delete(item.id)}
                              >
                                {" "}
                                <MdDelete className="color_link" />
                              </button>
                              {/* <Link style={{ '--i': 'red' }} onClick={() => {
                          Delete(item.cust_id);
                        }}> <MdDelete className="color_link" /></Link> */}
                            </OverlayTrigger>

                            {/* <OverlayTrigger
                          key="Delete"
                          placement="top"
                          overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                        >
                          <Link to="#"> <MdDelete className="color_link" /></Link>
                        </OverlayTrigger> */}

                            {/* <button onClick={() => handleClickCompanyId(item.id)}>View Details</button> */}

                            {/* <OverlayTrigger
                          key="Update"
                          placement="top"
                          overlay={<Tooltip id={`Update`}>Update</Tooltip>}
                        >
                          <Link to="/Viewdetailsupdate"><IoMdAdd className="color_link" />
                          </Link>
                        </OverlayTrigger> */}
                          </div>
                        </td>
                      </tr>
                      <tr className="hover-actions-trigger btn-reveal-trigger hover-bg-100">
                        <td className="align-middle" style={{ maxWidth: 30 }}>
                          <div className="form-check mb-0"></div>
                        </td>
                        <td className="align-middle white-space-nowrap">
                          <a
                            className="text-800"
                            href="../pages/user/profile.html"
                          >
                            <div className="d-flex align-items-center">
                              <div className="avatar avatar-xl">
                                <img
                                  className="rounded-circle"
                                  src={proifle}
                                  alt=""
                                />
                              </div>
                              <h6 className="mb-0 ps-2 name">Jenny Horas</h6>
                            </div>
                          </a>
                        </td>
                        <td className="align-middle white-space-nowrap text-primary email">
                          <a href="mailto:jenny@mail.ru">jenny@mail.ru</a>
                        </td>
                        <td className="align-middle white-space-nowrap">
                          <small className="badge fw-semi-bold rounded-pill status badge-subtle-warning">
                            {" "}
                            Cold Lead
                          </small>
                        </td>
                        <td className="align-middle priority pe-4">
                          <div className="d-flex gap-1 action_button">
                            <OverlayTrigger
                              key="Edit"
                              placement="top"
                              overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                            >
                              <button
                                // onClick={() => handleClickEditCompanyId(item.id)}
                                style={{ "--i": "#2c7be5" }}
                              >
                                {" "}
                                <FaRegEdit className="color_link" />
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key="View"
                              placement="top"
                              overlay={<Tooltip id={`View`}>View</Tooltip>}
                            >
                              <button
                                // onClick={() => handleClickCompanyId(item.id)}
                                style={{ "--i": "#27bcfd" }}
                              >
                                <FaEye className="color_link" />
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key="Delete"
                              placement="top"
                              overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                            >
                              <button
                                style={{ "--i": "red" }}
                                // onClick={() => Delete(item.id)}
                              >
                                {" "}
                                <MdDelete className="color_link" />
                              </button>
                              {/* <Link style={{ '--i': 'red' }} onClick={() => {
                          Delete(item.cust_id);
                        }}> <MdDelete className="color_link" /></Link> */}
                            </OverlayTrigger>

                            {/* <OverlayTrigger
                          key="Delete"
                          placement="top"
                          overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                        >
                          <Link to="#"> <MdDelete className="color_link" /></Link>
                        </OverlayTrigger> */}

                            {/* <button onClick={() => handleClickCompanyId(item.id)}>View Details</button> */}

                            {/* <OverlayTrigger
                          key="Update"
                          placement="top"
                          overlay={<Tooltip id={`Update`}>Update</Tooltip>}
                        >
                          <Link to="/Viewdetailsupdate"><IoMdAdd className="color_link" />
                          </Link>
                        </OverlayTrigger> */}
                          </div>
                        </td>
                      </tr>
                      <tr className="hover-actions-trigger btn-reveal-trigger hover-bg-100">
                        <td className="align-middle" style={{ maxWidth: 30 }}>
                          <div className="form-check mb-0"></div>
                        </td>
                        <td className="align-middle white-space-nowrap">
                          <a
                            className="text-800"
                            href="../pages/user/profile.html"
                          >
                            <div className="d-flex align-items-center">
                              <div className="avatar avatar-xl">
                                <img
                                  className="rounded-circle"
                                  src={proifle}
                                  alt=""
                                />
                              </div>
                              <h6 className="mb-0 ps-2 name">Chris Pratt</h6>
                            </div>
                          </a>
                        </td>
                        <td className="align-middle white-space-nowrap text-primary email">
                          <a href="mailto:pratt@mail.ru">pratt@mail.ru</a>
                        </td>
                        <td className="align-middle white-space-nowrap">
                          <small className="badge fw-semi-bold rounded-pill status badge-subtle-warning">
                            {" "}
                            Cold Lead
                          </small>
                        </td>
                        <td className="align-middle priority pe-4">
                          <div className="d-flex gap-1 action_button">
                            <OverlayTrigger
                              key="Edit"
                              placement="top"
                              overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                            >
                              <button
                                // onClick={() => handleClickEditCompanyId(item.id)}
                                style={{ "--i": "#2c7be5" }}
                              >
                                {" "}
                                <FaRegEdit className="color_link" />
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key="View"
                              placement="top"
                              overlay={<Tooltip id={`View`}>View</Tooltip>}
                            >
                              <button
                                // onClick={() => handleClickCompanyId(item.id)}
                                style={{ "--i": "#27bcfd" }}
                              >
                                <FaEye className="color_link" />
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key="Delete"
                              placement="top"
                              overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                            >
                              <button
                                style={{ "--i": "red" }}
                                // onClick={() => Delete(item.id)}
                              >
                                {" "}
                                <MdDelete className="color_link" />
                              </button>
                              {/* <Link style={{ '--i': 'red' }} onClick={() => {
                          Delete(item.cust_id);
                        }}> <MdDelete className="color_link" /></Link> */}
                            </OverlayTrigger>

                            {/* <OverlayTrigger
                          key="Delete"
                          placement="top"
                          overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                        >
                          <Link to="#"> <MdDelete className="color_link" /></Link>
                        </OverlayTrigger> */}

                            {/* <button onClick={() => handleClickCompanyId(item.id)}>View Details</button> */}

                            {/* <OverlayTrigger
                          key="Update"
                          placement="top"
                          overlay={<Tooltip id={`Update`}>Update</Tooltip>}
                        >
                          <Link to="/Viewdetailsupdate"><IoMdAdd className="color_link" />
                          </Link>
                        </OverlayTrigger> */}
                          </div>
                        </td>
                      </tr>
                      <tr className="hover-actions-trigger btn-reveal-trigger hover-bg-100">
                        <td className="align-middle" style={{ maxWidth: 30 }}>
                          <div className="form-check mb-0"></div>
                        </td>
                        <td className="align-middle white-space-nowrap">
                          <a
                            className="text-800"
                            href="../pages/user/profile.html"
                          >
                            <div className="d-flex align-items-center">
                              <div className="avatar avatar-xl">
                                <img
                                  className="rounded-circle"
                                  src={proifle}
                                  alt=""
                                />
                              </div>
                              <h6 className="mb-0 ps-2 name">Andy Murray</h6>
                            </div>
                          </a>
                        </td>
                        <td className="align-middle white-space-nowrap text-primary email">
                          <a href="mailto:andy@gmail.com">andy@gmail.com</a>
                        </td>
                        <td className="align-middle white-space-nowrap">
                          <small className="badge fw-semi-bold rounded-pill status badge-subtle-success">
                            {" "}
                            Won Lead
                          </small>
                        </td>
                        <td className="align-middle priority pe-4">
                          <div className="d-flex gap-1 action_button">
                            <OverlayTrigger
                              key="Edit"
                              placement="top"
                              overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                            >
                              <button
                                // onClick={() => handleClickEditCompanyId(item.id)}
                                style={{ "--i": "#2c7be5" }}
                              >
                                {" "}
                                <FaRegEdit className="color_link" />
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key="View"
                              placement="top"
                              overlay={<Tooltip id={`View`}>View</Tooltip>}
                            >
                              <button
                                // onClick={() => handleClickCompanyId(item.id)}
                                style={{ "--i": "#27bcfd" }}
                              >
                                <FaEye className="color_link" />
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key="Delete"
                              placement="top"
                              overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                            >
                              <button
                                style={{ "--i": "red" }}
                                // onClick={() => Delete(item.id)}
                              >
                                {" "}
                                <MdDelete className="color_link" />
                              </button>
                              {/* <Link style={{ '--i': 'red' }} onClick={() => {
                          Delete(item.cust_id);
                        }}> <MdDelete className="color_link" /></Link> */}
                            </OverlayTrigger>

                            {/* <OverlayTrigger
                          key="Delete"
                          placement="top"
                          overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                        >
                          <Link to="#"> <MdDelete className="color_link" /></Link>
                        </OverlayTrigger> */}

                            {/* <button onClick={() => handleClickCompanyId(item.id)}>View Details</button> */}

                            {/* <OverlayTrigger
                          key="Update"
                          placement="top"
                          overlay={<Tooltip id={`Update`}>Update</Tooltip>}
                        >
                          <Link to="/Viewdetailsupdate"><IoMdAdd className="color_link" />
                          </Link>
                        </OverlayTrigger> */}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer bg-body-tertiary p-0">
                <div className="pagination d-none" />
                {/* <a className="btn btn-sm btn-link d-block py-2" href="#!">
            Show full list
            <span className="fas fa-chevron-right ms-1 fs-11" />
          </a> */}

                <div className="d-flex justify-content-start p-3">
                  <button
                    className="btn btn-sm btn-outline-primary"
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage((prev) => prev - 1)}
                  >
                    <IoIosArrowBack />
                  </button>
                  <span className="px-2">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    className="btn btn-sm btn-outline-primary"
                    disabled={currentPage === totalPages}
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                  >
                    <IoIosArrowForward />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
export default Home;
