import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FaRegEdit, FaEye, FaSearch } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { MdNotificationsActive } from "react-icons/md";

export default function Notifications() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [totalReadNotifications, setTotalReadNotifications] = useState(0);
  const [totalUnreadNotifications, setTotalUnreadNotifications] = useState(0);
  const broker_id = localStorage.getItem("broker_id");
  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("bear_token");
      
      // Create a new FormData instance and append the smalladmins_id
      const formData = new FormData();
      formData.append('user_id', broker_id);
      // formData.append('user_id', broker_id);
  
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}super-admins-sent-toswan-wealth-notification-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data", // Note: Content-Type should be multipart/form-data for FormData
        },
        data: formData, // Pass the FormData instance as the request body
      };
  
      const response = await axios(config);
      console.warn("response.data", response.data.data);
      setData(response.data.data);
      setTotalNotifications(response.data.totalNotifications);
      setTotalReadNotifications(response.data.totalReadNotifications);
      setTotalUnreadNotifications(response.data.totalUnreadNotifications);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const validData = Array.isArray(data) ? data : [];
  
  const totalPages = Math.ceil(validData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = validData.slice(startIndex, endIndex);

  // const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const paginatedData = data.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };



  const handleClickNotificationId = (id) => {
    localStorage.setItem("notificationId", id);
    navigate("/NotificationReply");
  };
  const formatDate = (dateString) => {

    const [day, month, year] = dateString.split('-').map(Number);
    
 
    const date = new Date(year, month - 1, day); 
    

    let hours = date.getHours();
    const minutes = date.getMinutes();
    

    const ampm = hours >= 12 ? 'PM' : 'AM';
    
    hours = hours % 12;
    hours = hours ? hours : 12; 
    

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    
  
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${hours}:${formattedMinutes} ${ampm}`;
  };
  const removeHtmlTags = (text) => {
    // Check if text is null or undefined, return an empty string or handle it accordingly
    if (!text) {
      return ''; // Return an empty string if the text is null or undefined
    }
    return text.replace(/<[^>]*>/g, '');
  };
  
  // Usage example
  const result = removeHtmlTags(null); // This will now return an empty string instead of throwing an error
  console.log(result); // Output: ''
  
  return (
    <>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Notifications</h3>
            </div>
            <div className="col-lg-4 text-end">
              {/* <Link to="/Home" className="">
                <button className="btn btn-success">Back</button>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    
      <div className="card mb-3">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table align-middle table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Message</th>
                  <th>Your Reply</th>
                  <th>Super Admin Replied</th>
                  <th>Date & Time</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </td>
                  </tr>
                ) :paginatedData && paginatedData.length > 0 ? (
                  paginatedData &&  paginatedData.map((item, index) => (
                    <tr key={item.id}>
                      <td>{startIndex + index + 1}</td>
                      <td className="capitalize"> {removeHtmlTags(item.message)}</td>
                      <td className="capitalize"> {item.admin_replied || 'N/A'}</td>
                      <td className="capitalize"> {removeHtmlTags(item.super_admin_replied) || 'N/A'}</td>
                      <td>
                      <span className="notification-time">
                                    <span
                                      className="me-2"
                                      role="img"
                                      aria-label="Emoji"
                                      >
                                      💬
                                    </span>
                                      {formatDate(item.date)}
                                    {/* Just now */}
                                  </span>
                      </td>
                      {/* <td>
                        {item.status === 1 ? (
                          <span className="badge bg-success">Read</span>
                        ) : (
                          <span className="badge bg-danger">Unread</span>
                        )}
                      </td> */}
                      <td>
                       
                        <OverlayTrigger
                          overlay={<Tooltip id={`tooltip-view`}>View & Reply</Tooltip>}
                        >
                          <button
                            className="btn btn-link p-0 me-3"
                            onClick={() => handleClickNotificationId(item.id)}
                          >
                            <FaEye className="text-primary fs-5" />
                          </button>
                        </OverlayTrigger>
                        {/* <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-delete`}>Delete</Tooltip>
                          }
                        >
                          <button
                            className="btn btn-link p-0"
                            onClick={() => handleDelete(item.id)}
                          >
                            <MdDelete className="text-danger fs-5" />
                          </button>
                        </OverlayTrigger> */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No notifications found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          { totalPages && totalPages > 1 && (
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-outline-secondary"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack /> Previous
              </button>
              <button
                className="btn btn-outline-secondary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next <IoIosArrowForward />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
