
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { ArrowRight, EyeSlash, EyeFill } from 'react-bootstrap-icons';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [browserId, setBrowserId] = useState('');
  const navigate = useNavigate();
  const broker_id = localStorage.getItem("broker_id");
  const [Baseurl, setBaseurl] = useState(null);
  const [logoData, setLogoData] = useState(null);
  const [LogoDatashort, setLogoDatashort] = useState(null);

  useEffect(() => {
    const broker_id = localStorage.getItem("broker_id");
    const checkapi_token = localStorage.getItem("checkapi_token");
    const bear_token = localStorage.getItem("bear_token");

    if (broker_id && checkapi_token === bear_token) {
      navigate("/Home");
    } else if (!broker_id || checkapi_token !== bear_token) {
      navigate("/");
      localStorage.clear();
    }

    let browserIdd = Cookies.get('browserId');

    if (!browserIdd) {
      browserIdd = uuidv4();
      Cookies.set('browserId', browserIdd, { expires: 20 });
    }

    setBrowserId(browserIdd);

 
    fetchData();

  }, [navigate]);
  const fetchData = async () => {
    try {
     
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-wealth-logo-withoutlogin`,
       
      };

      const response = await axios(config);
      console.warn( response.data)
      const data = response.data;
      setLogoData(data.data.wealth_logo);
      setBaseurl(data.logo_url)
      setLogoDatashort(response.data.data.wealth_short_desc);
    } catch (error) {
      console.error("Error fetching data:", error);
      
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onInputChangeEmail = (e) => {
    setEmail(e.target.value.trim());
  };

  const onInputChangePassword = (e) => {
    setPassword(e.target.value.trim());
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const validateForm = () => {
    const errors = {};
    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();

    if (!trimmedEmail) {
      errors.email = 'Email is required';
    } 
    if (!trimmedEmail) {
      Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Email is required',
          color:"white",
          background: 'transparent',
      });
      return false;
  }
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(trimmedEmail)) {
      Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Invalid email format',
          color:"white",
          background: 'transparent',
      });
      return false;
  }
  
    if (!trimmedPassword) {
      errors.password = 'Password is required';
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const submit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      const url = `${process.env.REACT_APP_API_URL}login-swan-wealth`;

      const formData = new FormData();
      formData.append('email', email.trim());
      formData.append('password', password.trim());
      formData.append('browser_id', browserId);

      const config = {
        method: 'POST',
        url: url,
        data: formData,
      };

      const response = await axios(config);

      if (response.data.status === 1) {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: response.data.data.message,
          color:"white",
          background: 'transparent',
          timer: 2000,
        }).then((result) => {
          navigate('/home');
       
          localStorage.setItem('broker_id', response.data.data.id);
          localStorage.setItem('bear_token', response.data.bear_token);
          localStorage.setItem('browserId', browserId);
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: "Invalid email or password",
          color:"white",
          background: 'transparent',
        });
      }
      if (response.data.status === 0) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Invalid email no or password, please contact administrator',
            color:"white",
            background: 'transparent',
        });
    
    
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="" id="top">
      <div className="">
        <div className="row flex-center g-0">
          <div className="col-lg-12 col-xxl-12 position-relative">
            <img
              className="bg-auth-circle-shape"
              src="assets/img/icons/spot-illustrations/bg-shape.png"
              alt=""
              width={250}
            />
            <img
              className="bg-auth-circle-shape-2"
              src="assets/img/icons/spot-illustrations/shape-1.png"
              alt=""
              width={150}
            />
            <div className="card overflow-hidden z-1">
              <div className="card-body p-0">
                <div className="row g-0 full-height">
                  <div className="col-md-5 bgcolornew text-center d-flex align-items-start">
                     <div>
                     <div
                      className="position-relative p-4 pb-0 pt-md-5"
                      data-bs-theme="light"
                    >
                      <div
                        className="bg-holder bg-auth-card-shape"
                        style={{
                          backgroundImage:
                            "url(assets/img/icons/spot-illustrations/half-circle.png)"
                        }}
                      />
                      {/*/.bg-holder*/}
                      <div className="z-1 position-relative logo-pd">
                      
                    
                          <div>
                            <img
                              src={`${Baseurl}/${logoData}`}
                              alt="Company Logo"
                              style={{ width: "200px", height: "auto" }}
                            />
                           
                            <p className="mt-5 text-start">{LogoDatashort} </p>
                          </div>
                    
                      </div>
                    </div>
                     </div>
                  </div>
                  <div className="col-md-7 d-flex flex-center">
                    <div className="p-4 p-md-5 flex-grow-1">
                      <div className="row flex-between-center">
                        <div className="col-auto">
                          <h3>Swan Wealth</h3>
                        </div>
                      </div>
                      <form onSubmit={submit}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="card-email">
                            Email address
                          </label>
                          <input
                            value={email}
                            onChange={onInputChangeEmail}
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            id="card-email"
                            type="email"
                          />
                         
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="form-label" htmlFor="card-password">
                              Password
                            </label>
                          </div>
                          <div className="input-group">
                            <input
                              value={password}
                              onChange={onInputChangePassword}
                              className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                              id="card-password"
                              type={showPassword ? "text" : "password"}
                            />
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? (
                                <EyeSlash className="fs-3 eye" style={{ height: "21px" }} />
                              ) : (
                                <EyeFill className="fs-3 eye" style={{ height: "21px" }} />
                              )}
                            </button>
                            
                          </div>
                        </div>
                        <div className="row flex-between-center">
                          <div className="col-auto">
                            <div className="form-check mb-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="card-checkbox"
                                checked={rememberMe}
                                onChange={handleRememberMe}
                              />
                              <label
                                className="form-check-label mb-0"
                                htmlFor="card-checkbox"
                              >
                                Remember me
                              </label>
                            </div>
                          </div>
                          <div className="col-auto">
                            <Link className="fs-10" to="/Forgetpassword">
                              Forgot Password?
                            </Link>
                          </div>
                        </div>
                        <div className="mb-3">
                          <button
                            disabled={loading}
                            className="btn btn-primary d-block w-100 mt-3"
                            type="submit"
                          >
                            Log in
                            {loading && <Spinner animation="border" style={{ width: '15px', height: '15px', marginLeft: '10px' }} />}
                          </button>
                        </div>
                      </form>
                  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </main>
  );
}
