import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";

import { IoPersonSharp } from "react-icons/io5";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { GiCalendarHalfYear } from "react-icons/gi";
import { FcMoneyTransfer } from "react-icons/fc";
import { FaBuilding } from "react-icons/fa";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
// import { FaRegBuilding } from "react-icons/fa";
import optional from "../../assets/img/optional.jpg";
import { TbCalendarMonth } from "react-icons/tb";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { FaHandHoldingHand } from "react-icons/fa6";
import { IoMdPersonAdd } from "react-icons/io";
import {
  FaRegBuilding,
  FaMoneyCheckAlt,
  FaRegCalendarAlt,
  FaInfoCircle,
  FaTimesCircle,
} from "react-icons/fa";
import { GiReceiveMoney, GiTakeMyMoney, GiReturnArrow } from "react-icons/gi";

export default function ViewBusiness() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [business, setBusiness] = useState(null);
  const [baseurl, setBaseurl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [Baseurluser, setBaseurluser] = useState("");
  const [AllEmployee, setAllEmployee] = useState(''); 
  const [ActiveEmployee, setActiveEmployee] = useState(''); 
  const [InactiveEmployee, setInactiveEmployee] = useState(''); 
  const [InactiveEmployee2, setInactiveEmployee2] = useState(''); 
  useEffect(() => {
    const fetchBusiness = async () => {
      const token = localStorage.getItem("bear_token");
      const cust_idPlanView = localStorage.getItem("cust_idPlanView");
      const invest_plain_idPlanView = localStorage.getItem(
        "invest_plain_idPlanView"
      );
      const invest_idPlanView = localStorage.getItem("invest_idPlanView");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("cust_id", cust_idPlanView);
      formData.append("invest_plain_id", invest_plain_idPlanView);
      formData.append("investment_id", invest_idPlanView);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}customer-swan-wealth-investments-details`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.status === 1) {
          setBusiness(response.data.data);
          setBaseurl(response.data.plan_image_url);
          setBaseurluser(response.data.profile_image);
          setAllEmployee(response.data.totalCompanyEarnAmount);
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBusiness();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
    {/* <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:                       
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="mb-0">Customer Investment Plan Analytics</h2>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Single-Customer-Plan" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>

      </div> */}
      {/* <div className="row g-3 mb-3">
        <div className="col-md-4 col-xxl-3">
        <Link to="/Company-Earn" className="linkWithoutUnderline ">
          <div className="card h-md-100 ecommerce-card-min-width">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2 d-flex align-items-center">
                Total Company Earn Amount
              </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row align-items-center">
                <div className="col pe-0">
                  <GiTakeMyMoney className="fs-4 text-primary" />
                </div>
                <div className="col ps-0">
                  <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                    {AllEmployee || 0}
                  </p>
                </div>
                <div className="col-auto ps-0">
                  <div className="echart-bar-weekly-sales h-100" />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
   
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Total Customer</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <IoMdPersonAdd className="fs-4 text-success" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {ActiveEmployee || 0}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
       
        </div>
        <div className="col-md-4 col-xxl-3">
   

          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Total Company Parnter</h5> 
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  
              
                  <FaHandHoldingHand className="fs-4 text-danger" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                    <p className="font-sans-serif lh-1 mb-1 fs-5  text-danger">
                      {InactiveEmployee || 0}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
       
        </div>
   
      </div> */}
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Customer Investment Plan Details</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Single-Customer-Plan">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          {business &&
            business.map((business) => (
              <div className="row">
                <div className="col-lg-5 d-flex align-items-center">
                  <h5 className="mb-0 capitalize d-flex align-items-center">
                    {business.profile_image === null ? (
                      <>
                        <IoPersonSharp className="fs-6 text-dark me-1" />
                      </>
                    ) : (
                      <img
                        src={`${Baseurluser}/${business.profile_image}`}
                        heig
                        ht={35}
                        width={35}
                        // alt=""
                        className="img-fluid rounded-circle fs-6  me-1"
                      />
                    )}{" "}
                    {business.customer_name}
                  </h5>
                </div>
                <div className="col-lg-4 text-end">
                  <h3   className={`mb-0 ${business.user_credit > 0 ? 'greenWallet' : InactiveEmployee < 0 ? 'redWallet' : 'greenWallet'}`}>
                    {" "}
                    <MdOutlineAccountBalanceWallet className="fs-4 " />{" "}
                    {(business.customer_credit).toFixed(0)}
                  </h3>
                </div>
                <div className="col-lg-3 text-end">
                  <h6 className="mb-0 text-info">
                    {" "}
                    <MdOutlineMarkEmailRead className="fs-4 text-info" />{" "}
                    {business.customer_email}
                  </h6>
                </div>
              </div>
            ))}
        </div>
      </div>
    
      <div className="row">
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-header border-bottom">
              <div className="card-title mb-0">Investment Details</div>
            </div>
            <div className="card-body bg-body-tertiary">
              <div id="plan_new">
                <div className="row d-flex justify-content-center">
                  {business &&
                    business.map((business) => (
                      <div className="row">
                        <div className="col-md-12 mb-2">
                          <div class="plan" key={business.id}>
                            <div class="first-cut">
                              <div className="d-flex imageprofile">
                               
                                <img
                                    src={
                                      business.plan_investment_image
                                        ? `${baseurl}/${business.plan_investment_image}`
                                        : optional
                                    }
                                    alt="Plan Image"
                                    className="img-fluid"
                                  />
                              </div>
                              {business.plan_status === "approve" ? (
                                  <div class="popular">
                                  <div class="inner active">
                                    <p>Active</p>
                                  </div>
                                </div>
                                      
                                    ) : (
                                      <div class="popular">
                                        <div class="inner">
                                          <p>Expired</p>
                                        </div>
                                      </div>
                                    )}
                              <div className="text-center ">
                                <h3 className="capitalize">
                                  <strong> Plan Name :</strong>{" "}
                                  {business.plan_name}
                                </h3>
                              </div>
                              <div className="d-flex justify-content-between">
                              <ul className="subscription">
                                  <li>
                                    <FaRegCalendarAlt />
                                    <strong>Plan Start Date</strong>{" "}
                                    <span className="mx-2">:</span>
                                    {business.plan_duration_from}
                                  </li>
                                  <li>
                                    <FaRegCalendarAlt />
                                    <strong>Plan End Date</strong>{" "}
                                    <span className="mx-2">:</span>
                                    {business.plan_duration_to}
                                  </li>
                                  <li>
                                    <FaRegCalendarAlt />
                                    <strong>Plan Duration</strong>{" "}
                                    <span className="mx-2">:</span>
                                    {business.plan_duration} {business.plan_duration_type}
                                  </li>
                                  
                                  <li>
                                    <RiMoneyRupeeCircleFill />
                                    <strong>Invested Amount</strong>{" "}
                                    <span className="mx-2">:</span>{" "}
                                    {business.invested_amount}
                                  </li>
                               
                                  <li>
                                    {" "}
                                    <FaMoneyBillTrendUp />
                                    <strong>Interest Rate</strong>{" "}
                                    <span className="mx-2">:</span>{" "}
                                    {business.interest_rate} %
                                  </li>

                             

                                  <li className="d-flex">
                                    {" "}
                                    {business.is_compound === 1 ? (
                                      <FaMoneyCheckAlt />
                                    ) : (
                                      <FaTimesCircle />
                                    )}{" "}
                                    <strong>Compound Interest</strong>{" "}
                                    <span className="mx-2">:</span>{" "}
                                    <div
                                      className="d-flex align-items-center"
                                      style={{
                                        color:
                                          business.is_compound === 1
                                            ? "green"
                                            : "red",
                                      }}
                                    >
                                      {business.is_compound === 1
                                        ? "Applicable"
                                        : "Not Applicable"}
                                    </div>
                                  </li>
                                 
                                </ul>
                                <ul className="subscription">
                                  <li className="d-flex">
                                    <FaRegBuilding />
                                    <strong>Campany Name </strong>
                                    <span className="mx-2">:</span>{" "}
                                  
                                    {business.company_name}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>

   
    </>
  );
}
